/* eslint-disable no-unused-vars */

import setIconFiles from "./setIconFiles";
export default {
  setDataCompleteAdvanceSearch,
  setDataCompleteAdvanceSearch_
};

// Raw data file folder to Organize data file folder
function setDataCompleteAdvanceSearch(_rawdata, _accountid) {
  let dataFileFolder = [];
  if (_rawdata.data.status == "OK") {
    //  LOOP DATA FOR Organize files
    _rawdata.data["data"].forEach((data) => {
      let datafolder = {};
      let typefile = data["name"].split(".");
      datafolder["account_reciever"] = "";
      datafolder["account_sender"] = _accountid;
      if ("file_id" in data) {
        datafolder["file_id"] = data["file_id"];
      } else {
        datafolder["file_id"] = data["id"];
      }
      datafolder["file_name"] = data["name"];
      datafolder["file_size"] = data["size"];
      datafolder["file_status"] = data["status"];
      datafolder["file_owner_eng"] = data["name_eng"];
      datafolder["file_owner_th"] = data["name_th"];
      datafolder["file_linkshare"] = "";
      datafolder["file_lastdtm"] = data["last_dtm"];
      datafolder["file_date"] = "";
      datafolder["file_permission_2"] = data["permission_account_v2"];
      datafolder["file_parent_folder_id"] = data["folder_id"];
      if ("permission_account" in data) {
        datafolder["file_permission"] = data["permission_account"];
      } else {
        datafolder["file_permission"] = {
          admin_data: "False",
          delete: "False",
          download: "False",
          edit: "False",
          upload: "False",
          view_only: "True",
        };
      }

      datafolder["file_permission_department_setting"] = data["permission_department_setting"];
      datafolder["file_permission_setting"] = data["permission_setting"];
      datafolder["permission_business_setting"] = data["permission_business_setting"];
      datafolder["file_status_share"] = data["status_share"];
      datafolder["file_status_sharelink"] = data["status_share_link"];
      datafolder["file_type"] = data["data_type"];
      datafolder["data_type"] = typefile[typefile.length - 1];
      datafolder["status_updating"] = data["status_updating"];
      datafolder["file_department"] = "";
      datafolder["file_icon"] = data["data_type"] === "file" ? setIconFiles.fn_seticon(typefile[typefile.length - 1]) : setIconFiles.fn_seticon("folder");
      datafolder["status_updating"] = data["status_updating"];
      datafolder["status_lock_folder"] = data["status_lock_folder"];
      datafolder["status_lock_file"] = data["status_lock_file"];
      datafolder["status_lock_data"] = data["status_lock_data"];
      datafolder["file_thumbnail"] = "";
      datafolder["file_thumbnail_icon"] = setThumbnailIcon(typefile[typefile.length - 1]);
      datafolder["editing_status"] = data["editing_status"];
      datafolder["update_by"] = data["update_by"];
      datafolder["last_update_name"] = data["last_update_name"];
      datafolder["version_tag"] = data["version_tag"];
      datafolder["tag_id"] = data["tag_id"];
      datafolder["hashtag"] = data["hashtag"];
      datafolder["status_expired"] = data["status_expired"];
      datafolder["expiration_time"] = data["expiration_time"];
      datafolder["warning_expiration_time"] = data["warning_expiration_time"];

      dataFileFolder.push(datafolder);
    });
  }

  return dataFileFolder;
}

function setThumbnailIcon(_type) {
  let dataicon;
  let parameter;
  if (typeof _type === "string") {
    parameter = _type.toLowerCase();
  } else {
    parameter = _type;
  }
  if (parameter === "xlsx" || parameter === "xls") {
    dataicon = ["mdi-file-excel", "green"];
  } else if (parameter === "pptx" || parameter === "ppt") {
    dataicon = ["mdi-file-powerpoint", "orange"];
  } else if (parameter === "docx" || parameter === "doc") {
    dataicon = ["mdi-file-word", "primary"];
  } else if (parameter === "zip") {
    dataicon = ["mdi-zip-box", "blue-grey"];
  } else if (parameter === "pdf") {
    dataicon = ["mdi-file-pdf", "red"];
  } else if (parameter === "xml") {
    dataicon = ["mdi-file-code", "orange"];
  } else if (parameter === "txt" || parameter === "txt") {
    dataicon = ["mdi-note-text", "blue-grey"];
  } else if (parameter === "csv") {
    dataicon = ["mdi-file-table", "green"];
  } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png" || parameter === "gif") {
    dataicon = ["mdi-file-image", "blue-grey"];
  } else {
    dataicon = ["mdi-file", "black"];
  }
  return dataicon;
}

function setDataCompleteAdvanceSearch_(_rawdata, _accountid) {
  let dataFileFolder = [];
  console.log("_rawdata",_rawdata);
  let get_permission = sessionStorage.getItem("permission");
  let permission_get = JSON.parse(get_permission);

  console.log("permission_get",permission_get);
  if (_rawdata.data.status == "OK") {
    //  LOOP DATA FOR Organize files
    _rawdata.data["data"].forEach((data) => {
      let datafolder = {};
      let typefile = data["name"].split(".");
      datafolder["account_reciever"] = "";
      datafolder["account_sender"] = _accountid;
      if ("file_id" in data) {
        datafolder["file_id"] = data["file_id"];
      } else {
        datafolder["file_id"] = data["id"];
      }
      datafolder["file_name"] = data["name"];
      datafolder["file_size"] = data["size"] === null || data["size"] === "" ? "-" : data["size"];
      datafolder["file_status"] = data["status"];
      datafolder["file_owner_eng"] = data["name_eng"];
      datafolder["file_owner_th"] = data["name_th"];
      datafolder["file_linkshare"] = "";
      datafolder["file_lastdtm"] = data["last_dtm"];
      datafolder["file_date"] = "";
      datafolder["file_permission"] = "";
      datafolder["file_permission_2"] = permission_get;
      datafolder["file_parent_folder_id"] = permission_get;
      datafolder["permission_account"] = permission_get;
      
      // datafolder["file_parent_folder_id"] = data["folder_id"];
      // if ("permission_account" in data) {
      //   datafolder["file_permission"] = data["permission_account"];
      // } else {
      //   datafolder["file_permission"] = {
      //     admin_data: "False",
      //     delete: "False",
      //     download: "False",
      //     edit: "False",
      //     upload: "False",
      //     view_only: "True",
      //   };
      // }

      datafolder["file_permission_department_setting"] = data["permission_department_setting"];
      datafolder["file_permission_setting"] = data["permission_setting"];
      datafolder["file_status_share"] = data["status_share"];
      datafolder["file_status_sharelink"] = data["status_share_link"];
      datafolder["file_type"] = data["data_type"];
      datafolder["data_type"] = typefile[typefile.length - 1];
      datafolder["status_updating"] = data["status_updating"];
      datafolder["file_department"] = "";
      datafolder["file_icon"] = data["data_type"] === "file" ? setIconFiles.fn_seticon(typefile[typefile.length - 1]) : setIconFiles.fn_seticon("folder");
      // datafolder["file_icon"] = setIconFiles.iconExtensionFilesFolder_new(data["data_type"], typefile[typefile.length - 1]);
      datafolder["status_updating"] = data["status_updating"];
      datafolder["status_lock_folder"] = data["status_lock_folder"];
      datafolder["status_lock_file"] = data["status_lock_file"];
      datafolder["status_lock_data"] = data["status_lock_data"];
      datafolder["file_thumbnail"] = "";
      datafolder["file_thumbnail_icon"] = setThumbnailIcon(typefile[typefile.length - 1]);
      datafolder["editing_status"] = data["editing_status"];
      datafolder["update_by"] = data["update_by"];
      // datafolder["last_update_name"] = data["last_update_name"];
      datafolder["last_update_name"] = "";
      datafolder["version_tag"] = data["version_tag"];
      datafolder["tag_id"] = data["tag_id"];
      datafolder["hashtag"] = data["hashtag"];
      datafolder["status_expired"] = data["status_expired"];
      datafolder["expiration_time"] = data["expiration_time"];
      datafolder["warning_expiration_time"] = data["warning_expiration_time"];

      datafolder["status_hilightword"] = true
      datafolder["feature_shared_file"] = data["business_feature"];
      dataFileFolder.push(datafolder);
    });
  }
  return dataFileFolder;
}
