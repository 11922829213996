<template>
  <v-app :style="colorVapp">
    <onebox_toolbar
      :aspectSearch="aspectData"
      :quicksearch="true"
      :parentfolder="parentfolder"
      :permissionfolder="permission_currentfolder"
      :departmentid="''"
      @advancesearch="fn_advancesearch"
      @fulltextsearch="fulltextsearch"
      @inputdata="filteredItems"
      @loadfile="fn_loadfileandfolder_scrolling_child()"
      @callparentfolder="sendParentfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_toolbar>
    <v-content>
      <v-overlay :value="processloader" z-index="2" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
        <span :style="colorProgress">&nbsp; loading</span>
      </v-overlay>
      <v-card class="elevation-0">
        <!-- Header Page -->
        <v-list nav :color="color.BG">
          <v-list-item class="text-left" v-if="heardertextsearch === false">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">cloud</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage">&nbsp;{{ $t("toolbar.mydrive") }}</span>
              </v-list-item-title>
              <!-- <v-list-item-subtitle>
                 {{ $vuetify.breakpoint.name }}
              </v-list-item-subtitle> -->
            </v-list-item-content>
            <v-list-item-action class="pa-0 text-center">
              <v-btn
                @click="resetFile(), changepage(), changeLocalStorage()"
                outlined
                fab
                small
                :color="color.theme"
                v-if="resolutionScreen < 500 ? false : true"
              >
                <v-icon>{{ changeUI ? "view_list" : "view_module" }}</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="text-left" v-else>
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-magnify</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage">&nbsp;{{ $t("tablefile.searchtext") }}</span>
              </v-list-item-title>
              <!-- <v-list-item-subtitle>
                 {{ $vuetify.breakpoint.name }}
              </v-list-item-subtitle> -->
            </v-list-item-content>
            <v-list-item-action class="pa-0 text-center">
              <v-btn
                @click="resetFile(), changepage(), changeLocalStorage()"
                outlined
                fab
                small
                :color="color.theme"
                v-if="resolutionScreen < 500 ? false : true"
              >
                <v-icon>{{ changeUI ? "view_list" : "view_module" }}</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <!-- <dialog_filter
          :aspect="aspect"
        ></dialog_filter>-->

        <!-- Container Page -->
        <v-card class="elevation-0" :color="color.BG" id="thiscontainer_myfile">
          <v-card-text class="pa-0">
            <v-container @contextmenu="show" fluid>
              <v-layout row wrap fill-height justify-center>
                <!-- <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex> -->
                <v-layout row wrap class="pl-3 pr-3" v-if="resolutionScreen >= 500">
                  <v-flex lg2 xs4>
                    <!-- ส่วนของตัว select size -->
                    <!-- ไม่ได้ใช้แต่สร้างไว้จัดให้ layout ตรงกับหน้าอื่น -->
                  </v-flex>
                  <v-flex lg4 offset-lg6 class="pl-3 pr-3 hidden-md-and-down">
                    <v-alert
                      dense
                      height="41"
                      v-model="clickSort"
                      :color="color.alertSort"
                      dark
                      v-show="clickSort"
                      style="margin-bottom: 0%;"
                    >
                      <div>
                        <v-icon :color="color.alertText" v-if="multiSort === true">mdi-chevron-up</v-icon>
                        <v-icon :color="color.alertText" v-else-if="multiSort === false">mdi-chevron-down</v-icon>
                        <span :style="colorSort" v-if="sortby === 'name'">{{ $t("Filter.name") }}</span>
                        <span :style="colorSort" v-else-if="sortby === 'date'">{{ $t("Filter.date") }}</span>
                        <span :style="colorSort" v-else-if="sortby === 'size'">{{ $t("Filter.size") }}</span>
                        <span :style="colorSort" v-else-if="sortby === 'owner'">{{ $t("Filter.owner") }}</span>
                        <v-btn
                          width="24"
                          height="24"
                          style="float: right;"
                          text
                          fab
                          x-small
                          :color="color.alertSort"
                          dark
                          @click="checkSort"
                        >
                          <v-icon :color="color.alertText">mdi-close-circle</v-icon>
                        </v-btn>
                      </div>
                    </v-alert>
                  </v-flex>
                </v-layout>
                <v-flex lg12 class="ma-4">
                  <!-- Multiple Download, Starred , Move, Delete  -->
                  <v-layout v-if="resolutionScreen >= 500" row wrap fill-height class="pl-2 pr-2">
                    <!-- Button Multiple -->
                    <v-flex xs4 lg4>
                      <div v-if="statusmutipledownload === false" class="pt-2">
                        <v-btn
                          :color="color.theme"
                          :dark="color.darkTheme"
                          class="ma-1"
                          fab
                          small
                          outlined
                          @click="statusmutipledownload = true"
                          v-if="changeUI === false"
                        >
                          <v-icon>apps</v-icon>
                        </v-btn>
                      </div>
                      <div v-else class="pt-2">
                        <v-btn small fab :color="color.theme" :dark="color.darkTheme" class="ma-1" @click="multipledownload()">
                          <v-icon>mdi-download-multiple</v-icon>
                        </v-btn>
                        <v-btn
                          :dark="color.darkTheme"
                          :color="color.theme"
                          small
                          class="ma-1"
                          fab
                          @click="setmultiupdate(filemultipledownload, 'S')"
                        >
                          <v-icon dark>star</v-icon>
                        </v-btn>
                        <v-btn fab small :color="color.theme" :dark="color.darkTheme" class="ma-1" @click="multideletetotrash()">
                          <v-icon>delete</v-icon>
                        </v-btn>
                        <v-btn
                          fab
                          small
                          class="ma-1"
                          :color="color.theme"
                          :dark="color.darkTheme"
                          @click="appsbarmultimovefile($event)"
                        >
                          <v-icon>mdi-folder-swap</v-icon>
                        </v-btn>
                        <v-btn
                          color="error"
                          fab
                          small
                          class="ma-1"
                          @click="(statusmutipledownload = false), clearmultipledownload()"
                        >
                          <v-icon>clear</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                    <!-- Countfiles&Folders -->
                    <v-flex xs8 lg8 class="text-right">
                      <!-- OLD Countfiles -->
                      <!-- <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>folder</v-icon>
                        {{countfolder}} {{countfolder > 1 ? $t('folders'): $t('folder')}}
                      </v-chip>
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>mdi-file</v-icon>
                        {{countfile}} {{countfile > 1 ? $t('files'): $t('file')}}
                      </v-chip> -->
                      <!-- New Countfiles by scrolling -->
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>folder</v-icon>
                        {{ totaldata["totalfolder"] }} {{ totaldata["totalfolder"] > 1 ? $t("folders") : $t("folder") }}
                      </v-chip>
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>mdi-file</v-icon>
                        {{ totaldata["totalfile"] }} {{ totaldata["totalfile"] > 1 ? $t("files") : $t("file") }}
                      </v-chip>
                    </v-flex>
                  </v-layout>

                  <!-- หน้าจอxs -->
                  <v-layout v-else row wrap>
                    <!-- <v-flex lg2 xs3>
                      <v-select
                        prepend-inner-icon="sort"
                        hide-details
                        outlined
                        solo
                        dense
                        v-model="size"
                        :items="listsize"
                        class="text-center"
                      >
                        <template v-slot:item="{ item }">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                        <template v-slot:selection="{ item }">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                      </v-select>
                    </v-flex> -->
                    <v-flex xs12 sm12 lg12 class="text-right">
                      <!-- <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>folder</v-icon>
                        {{countfolder}} {{countfolder > 1 ? $t('folders'): $t('folder')}}
                      </v-chip>
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>mdi-file</v-icon>
                        {{countfile}} {{countfile > 1 ? $t('files'): $t('file')}}
                      </v-chip> -->

                      <!-- New Countfiles by scrolling -->
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>folder</v-icon>
                        {{ totaldata["totalfolder"] }}
                      </v-chip>
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>mdi-file</v-icon>
                        {{ totaldata["totalfile"] }}
                      </v-chip>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs12 lg12 class="pa-4">
                  <!-- ตรวจสอบขนาดหน้าจอ  -->
                  <div
                    v-if="
                      $vuetify.breakpoint.name === 'xl' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'md'
                    "
                  >
                    <!-- ตรวจสอบว่า layout Grid หรือไม่ -->
                    <div v-if="changeUI === true">
                      <!-- โชว์ไฟล์ที่เปิดล่าสุด -->
                      <v-card
                        class="elevation-0"
                        :color="color.BG"
                        v-show="filesLastView.length >= 1 && heardertextsearch === false"
                      >
                        <v-card-title
                          ><b>{{ $t("recentfile") }}</b></v-card-title
                        >
                        <template>
                          <v-layout align-center justify-center>
                            <v-flex lg11>
                              <swiper class="swiper" :options="swiperOption" ref="mySwiper">
                                <swiper-slide v-for="(item, index) in filesLastView" :key="index">
                                  <v-card
                                    @dblclick="
                                      gotodirectory(item.file_id, item.file_type, item.file_department, item, filesLastView)
                                    "
                                    hover
                                    outlined
                                    :title="item.file_name"
                                  >
                                    <v-layout align-center justify-center wrap column>
                                      <v-col>
                                        <v-flex align-self-center>
                                          <v-img
                                            :src="item.file_thumbnail"
                                            max-height="75px"
                                            height="75px"
                                            width="330px"
                                            v-if="item.file_thumbnail != ''"
                                          ></v-img>
                                          <div v-else class="text-center">
                                            <v-icon :color="item.file_thumbnail_icon[1]" size="75px">{{
                                              item.file_thumbnail_icon[0]
                                            }}</v-icon>
                                          </div>
                                        </v-flex>
                                        <v-row class="pt-3 pb-4">
                                          <v-flex lg10 align-self-center>
                                            <div
                                              style=" whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;color:#000;fontSize:16px;padding-left:8px"
                                              class="text-center"
                                            >
                                              <v-icon class large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
                                              {{ item.file_name }}
                                            </div>
                                          </v-flex>
                                          <v-flex lg2 align-self-center>
                                            <v-icon
                                              color="red"
                                              v-if="item.status_lock_data === 'Y' || item.status_lock_file === 'Y'"
                                              >lock</v-icon
                                            >
                                          </v-flex>
                                        </v-row>
                                      </v-col>
                                    </v-layout>
                                  </v-card>
                                </swiper-slide>
                                <div class="swiper-pagination" slot="pagination"></div>
                                <div class="swiper-button-prev" slot="button-prev"></div>
                                <div class="swiper-button-next" slot="button-next"></div>
                              </swiper>
                            </v-flex>
                          </v-layout>
                        </template>
                        <!-- <v-layout wrap row align-center justify-center>
                          <v-flex lg11>
                            <v-row>
                              <v-col>
                                <div style="width:100%;max-width:100%;overflow: hidden;">
                                  <slider ref="slider" :options="optionSlider">
                                    <slideritem v-for="(item, index) in filesLastView" :key="index" :style="styleSlider">
                                      <v-card class="elevation-0" 
                                        @dblclick="gotodirectory(item.file_id, item.file_type, item.file_department, item)"
                                        hover
                                        outlined
                                        width="100%"
                                      >
                                        <v-layout justify-center column align-center wrap>
                                          <v-col>
                                            <v-img contain :src="item.file_thumbnail" max-height="75px" height="75px" v-if="item.file_thumbnail != ''"></v-img>
                                            <div v-else class="text-center">
                                              <v-icon :color="item.file_icon[1]" size="75px" >{{ item.file_icon[0] }}</v-icon>
                                            </div>
                                            <v-row class="pt-3">
                                              <v-flex  align-self-center >
                                                <div
                                                  style=" whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;color:#000;fontSize:16px;padding-left:8px;"
                                                  class="justify flex-start"
                                                >
                                                  <v-icon class large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
                                                  {{ item.file_name }}
                                                </div>
                                              </v-flex>
                                            </v-row>
                                          </v-col>
                                        </v-layout>
                                      </v-card>
                                    </slideritem>
                                  </slider>
                                </div>
                              </v-col>
                            </v-row>
                          </v-flex>
                        </v-layout> -->
                      </v-card>
                      <br />
                      <drag-selector v-model="checkedList" @change="fn_handleDragSelectorChange(checkedList)">
                        <!-- แผนก / บริษัท -->
                        <v-card
                          class="elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="resetFile"
                          v-if="dataAccountActive['type'] === 'Business'"
                        >
                          <v-card-title
                            ><b>{{ $t("company") }}</b></v-card-title
                          >
                          <v-layout justify-center wrap row align-center>
                            <v-flex lg11>
                              <v-row>
                                <v-col
                                  v-for="(item, index) in rootfile.filter((item) => {
                                    return item.file_status === 'M';
                                  })"
                                  :key="index"
                                  cols="6"
                                  md="3"
                                >
                                  <drag-selector-item class="drag-selector__item" :value="item">
                                    <v-card
                                      class="pa-2 d-flex align-center"
                                      @contextmenu="rightclickfileandfolder($event, item)"
                                      @dblclick="
                                        gotodirectory(item.file_id, item.file_type, item.file_department, item, rootfile)
                                      "
                                      hover
                                      outlined
                                      :title="item.file_name"
                                      :color="selectColor(item.file_id, 'card')"
                                      @click.ctrl="removeCheckedListItem(item)"
                                      @click.exact="shiftSelect(item)"
                                    >
                                      <!-- v-on:keyup.ctrl="fn_checkkeyevent($event,item)" -->
                                      <div>
                                        <v-badge
                                          offset-x="10"
                                          offset-y="6"
                                          color="orange"
                                          :icon="vBadgeIcon(item)"
                                          v-if="
                                            (item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P') ||
                                              item.file_status_share === 'Y' ||
                                              item.status_updating === ''
                                          "
                                        >
                                          <v-icon large :color="item.file_icon[1]">{{
                                            item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0]
                                          }}</v-icon>
                                        </v-badge>
                                        <v-icon v-else large :color="item.file_icon[1]">{{
                                          item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0]
                                        }}</v-icon>
                                      </div>
                                      <div
                                        style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px"
                                        :style="selectColor(item.file_id, 'text')"
                                      >
                                        {{ item.file_name }}
                                      </div>
                                    </v-card>
                                  </drag-selector-item>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-card>
                        <!-- โฟล์เดอร์ -->
                        <v-card
                          class="elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="resetFile"
                          v-if="totaldata['totalfolder'] > 0"
                        >
                          <v-card-title
                            ><b>{{ totaldata["total_folder"] == 0 ? "" : $t("folders") }}</b></v-card-title
                          >
                          <v-layout justify-center wrap row align-center>
                            <v-flex lg11>
                              <v-row>
                                <v-col
                                  v-for="(item, index) in rootfile.filter((item) => {
                                    return (item.file_type === 'folder' || item.type === 'folder') && item.file_status !== 'M';
                                  })"
                                  :key="index"
                                  cols="6"
                                  md="3"
                                >
                                  <drag-selector-item class="drag-selector__item" :value="item">
                                    <v-card
                                      class="pa-2 d-flex align-center"
                                      @contextmenu="rightclickfileandfolder($event, item)"
                                      @dblclick="
                                        gotodirectory(item.file_id, item.file_type, item.file_department, item, rootfile)
                                      "
                                      hover
                                      outlined
                                      :title="item.file_name"
                                      :color="selectColor(item.file_id, 'card')"
                                      @click.ctrl="removeCheckedListItem(item)"
                                      @click.exact="shiftSelect(item)"
                                    >
                                      <!-- v-on:keyup.ctrl="fn_checkkeyevent($event,item)" -->
                                      <!-- @keyup.ctrl.67="test($event,item)" -->
                                      <!-- FILE ICON -->
                                      <div>
                                        <v-badge
                                          offset-x="10"
                                          offset-y="6"
                                          color="orange"
                                          :icon="vBadgeIcon(item)"
                                          v-if="(item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P') || item.file_status_share === 'Y'"
                                        >
                                          <v-icon large :color="item.file_icon[1]">{{
                                            item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0]
                                          }}</v-icon>
                                        </v-badge>
                                        <v-icon v-else large :color="item.file_icon[1]">{{
                                          item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0]
                                        }}</v-icon>
                                      </div>
                                      <!-- FILE NAME -->

                                      <div
                                        style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px"
                                        :style="selectColor(item.file_id, 'text')"
                                      >
                                        {{ item.file_name }}
                                        <div>
                                          <v-chip
                                            x-small
                                            dark
                                            v-show="
                                              item.status_expired === '0' ||
                                                item.status_expired === '1' || item.status_expired === '4'
                                            "
                                            :color="
                                              item.status_expired === '0'
                                                ? 'red'
                                                : item.status_expired === '1' || item.status_expired === '4'
                                                ? '#fc9208e6'
                                                : 'white'
                                            "
                                            >{{
                                              item.status_expired === "0"
                                                ? $t("statusfileexpired.expired")
                                                : item.status_expired === "1" || item.status_expired === '4'
                                                ? $t("statusfileexpired.nearexpired")
                                                : ""
                                            }}</v-chip
                                          >
                                          <v-icon v-show="item.status_expired === '2'" small color="green">access_time</v-icon>
                                        </div>
                                      </div>
                                      <v-spacer></v-spacer>
                                      <!-- icon lock -->
                                      <v-icon v-if="item.status_lock_folder === 'Y' || item.status_lock_data === 'Y'" color="error">lock</v-icon>
                                    </v-card>
                                  </drag-selector-item>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-card>
                        <!-- ไฟล์ -->
                        <v-card
                          class="elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="resetFile"
                          v-if="totaldata['totalfile'] !== 0"
                        >
                          <v-card-title
                            ><b>{{ totaldata["totalfile"] == 0 ? "" : $t("files") }}</b></v-card-title
                          >
                          <v-layout justify-center wrap row align-center>
                            <v-flex lg11>
                              <v-row>
                                <!-- || item.type !== 'folder' -->
                                <v-col
                                  class
                                  v-for="(item, index) in rootfile.filter((item) => {
                                    return item.file_type !== 'folder';
                                  })"
                                  :key="index"
                                  cols="6"
                                  md="3"
                                >
                                  <drag-selector-item class="drag-selector__item" :value="item">
                                    <v-card
                                      class="pa-2 d-flex align-center"
                                      @contextmenu="rightclickfileandfolder($event, item)"
                                      @dblclick="
                                        gotodirectory(item.file_id, item.file_type, item.file_department, item, rootfile)
                                      "
                                      hover
                                      outlined
                                      :title="item.file_name"
                                      :color="selectColor(item.file_id, 'card')"
                                      @click.ctrl="removeCheckedListItem(item)"
                                      @click.exact="shiftSelect(item)"
                                    >
                                      <!-- v-on:keyup.ctrl="fn_checkkeyevent($event,item)" -->
                                      <v-layout justify-center column align-center>
                                        <v-col class="pa-2">
                                          <!-- Thumbnail กับ Icon -->
                                          <!-- ถ้าเปลี่ยนขนาด ต้องแก้ตำแหน่ง top ของติดดาวด้วย -->
                                          <!-- {{item.file_thumbnail}} -->
                                          <v-img
                                            :src="item.file_thumbnail"
                                            max-height="100px"
                                            height="100px"
                                            v-if="item.file_thumbnail != ''"
                                          ></v-img>
                                          <div v-else class="text-center">
                                            <v-icon :color="item.file_thumbnail_icon[1]" size="100px">{{
                                              item.file_thumbnail_icon[0]
                                            }}</v-icon>
                                          </div>
                                          <v-row class="pt-3">
                                            <v-flex lg2 align-self-center>
                                              <v-badge
                                                color="orange"
                                                :icon="vBadgeIcon(item)"
                                                v-if="(item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P') || item.file_status_share === 'Y'"
                                                offset-x="10"
                                                offset-y="10"
                                              >
                                                <div>
                                                  <v-icon
                                                    color="orange"
                                                    v-if="item.file_status === 'S'"
                                                    style="position: absolute;z-index:1;left:-8px;top:-11px"
                                                    >star</v-icon
                                                  >
                                                  <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon>
                                                </div>
                                              </v-badge>
                                              <div v-else>
                                                <v-icon
                                                  color="orange"
                                                  v-if="item.file_status === 'S'"
                                                  style="position: absolute;z-index:1;left:-4px;top:117px"
                                                  >star</v-icon
                                                >
                                                <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon>
                                              </div>
                                            </v-flex>
                                            <v-flex lg8 align-self-center>
                                              <div
                                                style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px"
                                                :style="selectColor(item.file_id, 'text')"
                                              >
                                                {{ item.file_name }}
                                                    <!-- v-if="item.status_expired !== '3' && item.status_expired !== '2' && item.status_expired !== undefined && item.status_expired !== '' && item.status_expired !== null" -->
                                                <div>
                                                  <v-chip
                                                    x-small
                                                    dark
                                                    v-show="item.status_expired === '0' || item.status_expired === '1' || item.status_expired === '4'"
                                                    :color="item.status_expired === '0' ? 'red' : item.status_expired === '1' || item.status_expired === '4' ? '#fc9208e6' : ''"
                                                    >{{item.status_expired === "0" ? $t("statusfileexpired.expired") : item.status_expired === "1" || item.status_expired === '4' ? $t("statusfileexpired.nearexpired") : ""
                                                    }}</v-chip>
                                                    <!-- v-else-if="item.status_expired === '2'" -->
                                                  <v-icon v-show="item.status_expired === '2'" small color="green"
                                                    >access_time</v-icon
                                                  >
                                                </div>
                                              </div>
                                            </v-flex>
                                            <v-flex lg2 align-self-center>
                                              <v-icon v-if="item.status_lock_data === 'Y'" color="error" right>lock</v-icon>
                                            </v-flex>
                                          </v-row>
                                        </v-col>
                                      </v-layout>
                                    </v-card>
                                  </drag-selector-item>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-card>

                        <v-card
                          v-if="rootfile.length === 0 && disablescrolling === true && loaddataprogress === false"
                          class="pb-4 elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="resetFile"
                        >
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                class="text-center"
                                v-text="heardertextsearch === false ? $t('tablefile.empty') : $t('tablefile.emptysearch')"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </drag-selector>
                    </div>
                    <!-- ตรวจสอบว่า layout table -->
                    <div v-else>
                      <v-data-table
                        v-model="filemultipledownload"
                        :headers="headers"
                        :items="rootfile"
                        item-key="file_id"
                        :single-select="false"
                        :search="search"
                        class="elevation-0 drag-selector pa-1"
                        :show-select="statusmutipledownload"
                        item-selected
                        :no-data-text="heardertextsearch === false ? $t('tablefile.empty') : $t('tablefile.emptysearch')"
                        :items-per-page="rootfile.length"
                        :hide-default-footer="true"
                      >
                        <!-- <template v-slot:top>
                                <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                            </template>-->
                        <template v-slot:[`header.file_type`]="{ header }">
                          <span :style="headerTable">{{ header.text }}</span>
                        </template>
                        <template v-slot:[`header.file_name`]="{ header }">
                          <span
                            class="pointer"
                            @click="(multiSort = !multiSort), (sortby = 'name'), (clickSort = true), sortFile(rootfile)"
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_owner`]="{ header }">
                          <span
                            class="pointer"
                            @click="(multiSort = !multiSort), (sortby = 'owner'), (clickSort = true), sortFile(rootfile)"
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_lastdtm`]="{ header }">
                          <span
                            class="pointer"
                            @click="(multiSort = !multiSort), (sortby = 'date'), (clickSort = true), sortFile(rootfile)"
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_size`]="{ header }">
                          <span
                            class="pointer"
                            @click="(multiSort = !multiSort), (sortby = 'size'), (clickSort = true), sortFile(rootfile)"
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_status`]="{ header }">
                          <span :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <!-- --------------------------------------------------------------------------------------------------------------------------------- -->
                        <template v-slot:item="props">
                          <tr
                            style="cursor: pointer"
                            @contextmenu="rightclickfileandfolder($event, props.item)"
                            @dblclick="
                              gotodirectory(
                                props.item.file_id,
                                props.item.file_type,
                                props.item.file_department,
                                props.item,
                                rootfile
                              )
                            "
                          >
                            <td class="text-center" v-show="statusmutipledownload">
                              <v-checkbox
                                v-model="filemultipledownload"
                                :value="props.item"
                                v-if="props.item.file_status !== 'M'"
                              />
                            </td>
                            <td width="7%" class="text-center" style="padding:0px;margin:0px;">
                              <v-badge color="orange" overlap icon="link" v-if="props.item.file_status_sharelink === 'Y' || props.item.file_status_sharelink === 'P'">
                                <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                              </v-badge>
                              <v-icon v-else large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                              <div>
                                <v-chip
                                  x-small
                                  dark
                                  v-show="
                                    props.item.status_expired === '0' ||
                                      props.item.status_expired === '1' || props.item.status_expired === '4'
                                   
                                  "
                                  :color="
                                    props.item.status_expired === '0'
                                      ? 'red'
                                      : props.item.status_expired === '1' || props.item.status_expired === '4'
                                      ? '#fc9208e6'
                                      : 'white'
                                  "
                                  >{{
                                    props.item.status_expired === "0"
                                      ? $t("statusfileexpired.expired")
                                      : props.item.status_expired === "1" || props.item.status_expired === '4'
                                      ? $t("statusfileexpired.nearexpired")
                                      : ""
                                  }}</v-chip
                                >
                              </div>
                            </td>
                            <td width="33%" :title="props.item.file_name" style="padding:0px;margin:0px;">
                              <div style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;width:375px">
                                <v-icon v-if="props.item.status_lock_data === 'Y'" color="error" small>lock</v-icon>
                                {{ props.item.file_name | subStr }}
                              </div>
                              <v-icon v-if="props.item.status_expired === '2'" small color="green">access_time</v-icon>
                            </td>
                            <td width="15%" style="padding:0px;margin:0px;" v-if="$t('default') === 'th'">
                              <div style="justify-content: center;">
                                {{ props.item.file_owner_th }}
                                <v-icon small color="orange" v-if="props.item.file_status_share === 'Y'">group_add</v-icon>
                              </div>
                            </td>
                            <td width="15%" style="padding:0px;margin:0px;" v-else>
                              <div style="justify-content: center;">
                                {{ props.item.file_owner_eng }}
                                <v-icon small color="orange" v-if="props.item.file_status_share === 'Y'">group_add</v-icon>
                              </div>
                            </td>
                            <td width="18%" style="padding:0px;margin:0px;">{{ formatdatetime(props.item.file_lastdtm) }}</td>
                            <td width="15%" style="padding:0px;margin:0px;">
                              {{
                                fn_calculatesize(props.item.file_size) == "0.00 B" ? "-" : fn_calculatesize(props.item.file_size)
                              }}
                            </td>
                            <td
                              width="10%"
                              class="text-center"
                              v-if="props.item.file_status === 'M'"
                              style="padding:0px;margin:0px;"
                            ></td>

                            <td width="12%" v-else class="text-right" style="padding:0px;margin:0px;">
                              <v-btn
                                class="elevation-0"
                                fab
                                small
                                @click="setPermissionFileStar(props.item, 'N')"
                                v-if="props.item.file_status === 'S'"
                              >
                                <v-icon color="amber">star</v-icon>
                              </v-btn>
                              <v-btn class="elevation-0" fab small @click="setPermissionFileStar(props.item, 'S')" v-else>
                                <v-icon>star</v-icon>
                              </v-btn>
                              <v-btn class="elevation-0" fab small @click="rightclickfileandfolder($event, props.item)" outlined>
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                  </div>

                  <!-- ตรวจสอบว่าเป็นจอ มือถือ -->
                  <div v-else class="ma-2">
                    <v-list two-line class="mb-6 pb-6 elevation-0" v-if="rootfile.length > 0">
                      <v-list-item
                        v-for="(item, index) in rootfile"
                        :key="index"
                        @contextmenu="rightclickfileandfolder($event, item)"
                        @dblclick="
                          gotodirectory(item.file_id, item.file_type, item.file_department, item, rootfile), newpreviewfile(item)
                        "
                        style="cursor: pointer;"
                      >
                        <v-list-item-action>
                          <v-badge color="orange" overlap icon="link" v-if="item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P'">
                            <v-icon large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
                          </v-badge>
                          <v-icon v-else large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
                          <!-- <v-chip
                                    x-small
                                    dark
                                    v-if="props.item.status_expired !== '3'&& props.item.status_expired !== undefined && props.item.status_expired !== '' && props.item.status_expired !== null"
                                    :color="props.item.status_expired === '0' ? 'red':props.item.status_expired === '1' ? '#fc9208e6':props.item.status_expired === '2' ? '#fc9208e6':'white'"
                                  >{{props.item.status_expired === '0' ? 'หมดอายุ': props.item.status_expired === '1' ? 'ใกล้หมดอายุ':props.item.status_expired === '2' ? 'ใกล้หมดอายุ':''}}</v-chip> -->
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title v-text="item.file_name"></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="fn_calculatesize(item.file_size) == '0.00 B' ? '-' : fn_calculatesize(item.file_size)"
                          ></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn class="elevation-0" fab small @click.stop="rightclickfileandfolder($event, item)" outlined>
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <v-list
                      v-if="rootfile.length === 0 && disablescrolling === true && loaddataprogress === false"
                      class="elevation-0"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-center"
                            v-text="heardertextsearch === false ? $t('tablefile.empty') : $t('tablefile.emptysearch')"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-flex>
              </v-layout>
              <scroll-loader class="pa-0" :loader-method="fn_loadfileandfolder_scrolling" :loader-disable="disablescrolling">
              </scroll-loader>

              <div class="text-center" v-if="loaddataprogress === true">
                <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
              </div>
            </v-container>
            <previewfile
              :show="openpreviewfile"
              :filedata_1="currentfile"
              :arrayfile="arrayfile"
              :parentfolder="parentfolder"
              @closepreviewfile="openpreviewfile = false"
              @openpreviewfile="openpreviewfile = true"
              @closeDrag="removeEvent()"
              @openDrag="addEvent()"
            ></previewfile>
            <!-- <newpreviewfile
              :parentfolder="parentfolder"
              :show="opennewpreviewfile"
              :filedata="currentfile"
              @closenewpreviewfile="opennewpreviewfile = false"
              @opennewpreviewfile="opennewpreviewfile = true"
            ></newpreviewfile>-->
            <uploadfiles
              :show="openUploadfiles"
              :parentfolder="parentfolder"
              :departmentid="departmentid"
              @close="loadstorage(), fn_loadfileandfolder_scrolling_child(), (openUploadfiles = false)"
            ></uploadfiles>

            <uploadfolder
              :show="openUploadfolder"
              :parentfolder="parentfolder"
              :departmentid="departmentid"
              @close="fn_loadfileandfolder_scrolling_child(), (openUploadfolder = false)"
              @closeDrag="removeEvent()"
              @openDrag="addEvent()"
            ></uploadfolder>
            <mainrightclick
              :departmentid="''"
              :show="showMenu"
              :permissionfolder="permission_currentfolder"
              :parentfolder="parentfolder"
              :AreaX="x"
              :AreaY="y"
              @loaddatafrommainright="loadstorage(), fn_loadfileandfolder_scrolling_child(), (showMenu = false)"
              @closemainonly="showMenu = false"
              @closeDrag="removeEvent()"
              @openDrag="addEvent()"
            ></mainrightclick>
            <!-- <listfile @closemaincontextmenu="showMenu = false"></listfile> -->
            <currentfilerightclick
              :confirm="confirmdelete"
              :parentfolder="parentfolder"
              :show="showcurrentMenu"
              :AreaX="x"
              :AreaY="y"
              @callfile="gotodirectory"
              @loadfolder="fn_loadfileandfolder_scrolling_child(), (statusmutipledownload = false)"
              @callparantfolder="gotoparent"
              @callupdatestatusfile="setPermissionFileStar"
              @newmultidelete="multideletetotrash"
              @closecurrent="loadstorage(), fn_loadfileandfolder_scrolling_child(), (showcurrentMenu = false)"
              @closecurrentonly="showcurrentMenu = false"
              :file="currentfile"
              :arrayfile="rootfile"
              :fileLength="filemultipledownload.length"
              :filemultipledownload="filemultipledownload"
              @closeDrag="removeEvent()"
              @openDrag="addEvent()"
              @reload="fn_loadfileandfolder_scrolling_child()"
            ></currentfilerightclick>
            <rightcheckpassword
              :show="opendialog_right"
              :filedata="newdata"
              @closedialog="opendialog_right = false"
              @callapi="loadstorage(), fn_loadfileandfolder_scrolling_child(), (opendialog_right = false)"
            />
            <confirmmovefile
              :dialog="open_confirmdialog"
              @closedialog="open_confirmdialog = false"
              @movefile="moveFileToFolder"
            ></confirmmovefile>
            <multifilerightclick
              :show="rightclickmultifile"
              :AreaX="x"
              :AreaY="y"
              :filemultipledownload="filemultipledownload"
              :file="currentfile"
              :parentfolder="parentfolder"
              @closecurrentonly="rightclickmultifile = false"
              @reload="loadstorage(), fn_loadfileandfolder_scrolling_child(), (filemultipledownload = []), (checkedList = [])"
              @clearparentvalue="clearparentvalue()"
              @closeappsbar="statusmutipledownload = false"
              @multideleteforbusinsess="multideletetotrash()"
            ></multifilerightclick>
            <multi_movefile
              :show="opendialogmultimove"
              :filemultipledownload="filemultipledownload"
              :parentfolder="parentfolder"
              @closedialog="opendialogmultimove = false"
              @reload="fn_loadfileandfolder_scrolling_child(), (filemultipledownload = []), (checkedList = [])"
              @closeappsbar="statusmutipledownload = false"
            ></multi_movefile>
            <dialogsnackbermultidowload
              :show="opensnackbarmultidownload"
              @closedialog="opensnackbarmultidownload = false"
              :percent="percent"
              :date="datetime"
            ></dialogsnackbermultidowload>
            <checkmultidelete
              :show="openchekmultidelete"
              @closedialog="(openchekmultidelete = false), (filemultipledownload = [])"
              @closedialogreload="
                (openchekmultidelete = false), (filemultipledownload = []), loadstorage(), fn_loadfileandfolder_scrolling_child()
              "
              :filedata="filemultipledownload"
              :status_data="status_data"
              @closeDrag="removeEvent()"
              @openDrag="addEvent()"
              @closebuttonmulti="(statusmutipledownload = false), clearmultipledownload()"
            ></checkmultidelete>
            <dialogcheckmultidownload
              :show="opendialogcheckmultidownload"
              @closedialog="(opendialogcheckmultidownload = false), (filemultipledownload = [])"
              :data="filemultipledownload"
            ></dialogcheckmultidownload>
            <dialogcheckdeleterightclick
              :show="opensdialogcheckdeleterightclick"
              @closedialog="opensdialogcheckdeleterightclick = false"
              @closedialogreload="
                (opensdialogcheckdeleterightclick = false), loadstorage(), fn_loadfileandfolder_scrolling_child()
              "
              :filedata="currentfile"
            ></dialogcheckdeleterightclick>
          </v-card-text>
          <!-- <infinite-loading @infinite="fn_loadfileandfolder_scrolling_infinite"></infinite-loading> -->
        </v-card>
      </v-card>
    </v-content>
    <!-- Drop here -->
    <dragdropupload
      :removeevent="removeevent"
      :addevent="addevent"
      :departmentid="departmentid"
      @loadfile="loadstorage(), fn_loadfileandfolder_scrolling_child()"
      :parentfolder="parentfolder"
      @snackbarupload="removeevent = true"
      @detailupload="removeevent = true"
    ></dragdropupload>
    <div>
      <v-snackbar v-model="snackbardowload" bottom right>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    </div>
    <!-- snackbardowload -->
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbardowloadfile" bottom class="v-snack__content" color="white" :timeout="timeoutdowload">
        <v-list color="white" style="padding-top: 0px;">
          <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
            {{ $t("myinboxPage.dowload.dowloading") }}
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="snackbardowloadfile = false" fab x-small>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list-item dense style="height:40px; width:400px;">
            <v-list-item-content>
              <v-list-item-title>{{ datetime }}</v-list-item-title>
            </v-list-item-content>
            <v-progress-circular
              :rotate="90"
              :size="35"
              :width="3"
              :value="percentCompleted"
              color="teal"
              style="margin-left:25px"
              >&nbsp; {{ percentCompleted }}</v-progress-circular
            >
          </v-list-item>
        </v-list>
      </v-snackbar>
    </div>
  </v-app>
</template>
<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

// ของ version 6 ใช้ไม่ได้
// import 'swiper/swiper-bundle.css'
// ของ version < 6
import "swiper/css/swiper.css";
// Doc
// https://warpcgd.github.io/vue-concise-slider/demos/Multiple.html
import { slider, slideritem } from "vue-concise-slider";

// Import Component
const uploadfolder = () => import("../components/upload/dialog-uploadfolder");
const uploadfiles = () => import("../components/upload/dialog-uploadfiles");
const currentfilerightclick = () => import("../components/contextmenu/currentfilerightclick");
const multifilerightclick = () => import("../components/contextmenu/multifilerightclick");
const mainrightclick = () => import("../components/contextmenu/mainrightclick");
const previewfile = () => import("../components/optional/dialog-preview");
const confirmmovefile = () => import("../components/optional/dialog-confirmmovefile");
const rightcheckpassword = () => import("../components/optional/dialog-rightclickcheckpassword.vue");
const multi_movefile = () => import("../components/optional/dialog-multimovefile");
const dialogsnackbermultidowload = () => import("../components/optional/dialog-snackbarmultidowmload");
const dragdropupload = () => import("../components/optional/dialog-dragdropupload-chunk");
const dialog_filter = () => import("../components/optional/dialog-filter");
const checkmultidelete = () => import("../components/optional/dialog-checkmultidelete");
const dialogcheckmultidownload = () => import("../components/optional/dialog-checkmultidownload");
const dialogcheckdeleterightclick = () => import("../components/optional/dialog-checkdeleterightclick");
import onebox_toolbar from "../components/layout/layout-toolbar-new";

// Import GlobalFunction
import gb_advanceSearch from "@/globalFunctions/advanceSearch";
import gb_setIconFiles from "@/globalFunctions/setIconFiles";
import gb_sizeDataConverter from "@/globalFunctions/sizeDataConverter";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import axios from "axios";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {
    dragdropupload,
    dialog_filter,
    uploadfiles,
    InfiniteLoading,
    onebox_toolbar,
    mainrightclick,
    VueFullScreenFileDrop,
    uploadfolder,
    currentfilerightclick,
    previewfile,
    slider,
    slideritem,
    confirmmovefile,
    multifilerightclick,
    rightcheckpassword,
    multi_movefile,
    dialogsnackbermultidowload,
    checkmultidelete,
    dialogcheckmultidownload,
    Swiper,
    SwiperSlide,
    dialogcheckdeleterightclick,
  },
  data: function() {
    return {
      checkdatacopy: [],
      choosecopy: [],
      heardertextsearch: false,
      listcopy: [],
      arrayfile: [],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      opensdialogcheckdeleterightclick: false,
      departmentid: "",
      opendialogcheckmultidownload: false,
      status_data: "",
      openchekmultidelete: false,
      removeevent: false,
      addevent: false,
      opensnackbarmultidownload: false,
      percent: 0,
      disablescrolling: true,
      totaldata: { totalall: 0, totalfile: 0, totalfolder: 0 },
      opendialogmultimove: false,
      parentfolderDD2: "",
      newdata: "",
      opendialog_right: false,
      opendialog_checklog: false,
      rightclickmultifile: false,
      open_confirmdialog: false,
      target_folder: "",
      holdfile_drag: [],
      filelist: [],
      selectmulti: [],
      aspect: "",
      aspectData: [{ aspect: "False", aspect_key: [] }],
      permission_currentfolder: {
        download: "False",
        edit: "False",
        view_only: "True",
        upload: "False",
        delete: "False",
      },
      checkCC: false,
      checkCCFolder: false,
      multiple: true,
      changeUI: localStorage.getItem("changeStoreUI") === "true" ? true : false,
      checkedList: [],
      timeoutdowload: 60000000,
      percentCompleted: 0,
      datetime: "",
      timeout: 0,
      interval: {},
      value: 0,
      countfile: "",
      countfolder: "",
      sortby: "",
      clickSort: false,
      myDateDownload: new Date().toUTCString(),
      statusmutipledownload: false,
      filemultipledownload: [],
      search: "",
      overlay: true,
      page: 1,
      size: 30,
      listsize: [10, 20, 50, 100],
      lists: [],
      pagination: {
        rowsPerPage: 100000,
        sortBy: "file_id",
        totalItems: 0,
        rowsPerPageItems: [1, 2, 4, 8],
      },
      openpreviewfile: false,
      loaddataprogress: true,
      parentfolder: "",
      loadingfile: false,
      openUploadfolder: false,
      openUploadfiles: false,
      showcurrentMenu: false,
      showMenu: false,
      currentfile: {
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: "",
      },
      x: 0,
      y: 0,
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "#",
          align: "left",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "file_name",
          width: "33%",
          sortable: false,
        },
        {
          text: "tablefile.owner",
          align: "left",
          value: "file_owner",
          width: "15%",
          sortable: false,
        },
        {
          text: "tablefile.lastmodified",
          value: "file_lastdtm",
          width: "18%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "15%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.option",
          value: "file_status",
          width: "12%",
          align: "left",
          sortable: false,
        },
      ],
      listDataUpload: [],
      multiSort: false,
      rootfile: [],
      folderSort: [],
      fileSort: [],
      sortdata: [],
      confirmdelete: false,
      buff: [],
      listUpload: [],
      snackbar: false,
      snackbardowload: false,
      showStatus: "",
      hover: false,
      snackbardowloadfile: false,
      itemC: [],
      checkCe: false,
      newrootfile: [],
      fileCount: 0,
      filterFile: [],
      filterFolder: [],
      filesLastView: [],
      optionSlider: {
        currentPage: 0,
        infinite: 3,
        slidesToScroll: 1,
        loop: true,
        pagination: false,
        loopedSlides: 1,
        autoplay: "10000",
      },
      styleSlider: {
        background: "#fff",
        width: "32.3%",
        // 33.3
        "margin-right": "1%",
        "border-radius": "5px",
      },
      parentfolderDD: "",
      parentfolderDDfile: "",
      re1: "",
      checkpath: "",
      folderupload: [],
      rootname: "",
      totalcountt: 0,
      rootFolder: "",
      count: 0,
      total: 0,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "service", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        // Old
        let l = this.rootfile.length;

        // New api
        // let l = this.fileCount;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.rootfile.slice(start, start + this.size);
    },
    showdatafilter() {
      // console.log(search);
      return this.rootfile.filter((item) => {
        return item.file_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      // console.log(this.color.theme);

      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      //return "color:" + this.color.theme + ";";
      return "color:" + "white" + ";";
    },
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 50) return string.substring(0, 50) + "...";
        else return string;
      }
    },
  },
  watch: {
    // checkedList(val){
    //   console.log('watch checkedlist:',val)
    // },
    rightclickmultifile(val) {
      if (val === false) this.checkedList = [];
    },
    permission_currentfolder(val) {
      if (val["upload"] == "False") {
        this.removeEvent();
      } else {
        this.addEvent();
      }
    },
  },
  methods: {
    //เช็ค ctrlkey
    fn_checkkeyevent(e) {
      // e.preventDefault();
      // e.stopPropagation();
      // if(this.$router.currentRoute.path === "/mydrive"){
      console.log("e", e);
      // console.log("item",item);
      this.checkdatacopy = [];

      if (e.ctrlKey == true && e.code === "KeyC") {
        console.log("choosecopy", this.choosecopy);
        this.checkdatacopy = Array.from(new Set(this.choosecopy));
        console.log("checkdatacopy", this.checkdatacopy);
        console.log("KeyC");
        //  for(let i; i < this.choosecopy.length; i++){
        //    if(this.choosecopy[i].file_type === 'folder'){
        //       Toast.fire({
        //       icon: "error",
        //       title: "ไม่สามารถคัดลอกโฟลเดอร์ได้",
        //     });
        //    }else{
        //      this.fn_copyfile(this.choosecopy)
        //    }
        //  }
        this.fn_copyfile(this.checkdatacopy);
      } else if (e.ctrlKey == true && e.code === "KeyV") {
        console.log("KeyV");
        this.fn_pastefile();
      }
      // }
    },
    //เก็บไฟล์ใน sessionStorage
    fn_copyfile(item) {
      console.log("fn_copyfile", item);
      this.listcopy = [];
      for (var i = 0; i < item.length; i++) {
        console.log("i*tem*", item[i]);
        // เช็คว่าเป็น folder หรือ ไฟล์ ถ้าเป็นไฟล์ push ใส่ listcopy
        if (item[i].file_type === "folder") {
        } else if (item[i].file_permission.download === "True" && item[i].status_lock_data === "N") {
          this.listcopy.push(item[i]);
        }
      }
      //เช็ค listcopy ว่าเท่ากับ 0 มั้ยถ้าเท่า(เลือกโฟลเดอร์) ให้แจ้งเตือนถ้ามากกว่า 0 (เลือกไฟล์) ให้เก็บใน sessionstorage
      if (this.listcopy.length == 0) {
        Toast.fire({
          icon: "error",
          title: "กรุณาเลือกไฟล์ที่ต้องการคัดลอก",
        });
      } else {
        console.log("listcopy", this.listcopy);
        sessionStorage.setItem("filecopy", JSON.stringify(this.listcopy));
        Toast.fire({
          icon: "success",
          title: "คัดลอกไฟล์",
        });
        console.log("************ ctrl+c *************");
      }
    },
    async fn_pastefile() {
      // this.rootfile = [];
      this.loaddataprogress = true;
      this.listfilecopy = [];
      // console.log("this.choosecopy",this.choosecopy);
      //เอาข้อมูลที่ลากไฟล์มา ที่ซ้ำออก
      this.checkdatapaste = Array.from(new Set(this.choosecopy));
      console.log("this.checkdatapaste", this.checkdatapaste);
      // getค่าจาก sessionstorage
      let data_copy = sessionStorage.getItem("filecopy");
      let data_paste = JSON.parse(data_copy);
      console.log("data_paste", data_paste);
      //วนเพื่อปั้นข้อมูลที่จะส่งไป api
      for (let i = 0; i < data_paste.length; i++) {
        this.listfilecopy.push({
          data_type: data_paste[i].file_type === "folder" ? "folder" : "file",
          data_id: data_paste[i].file_id,
        });
      }
      console.log("this.listfilecopy***", this.listfilecopy);

      let payload = {
        account_id: this.dataAccountId,
        folder_id: this.dataAccountActive.directory_id,
        copied: this.listfilecopy,
      };
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth);
      let resp = await axios.post(process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/paste_data", payload, {
        headers: { Authorization: auth.code },
      });
      console.log(resp);
      try {
        if (resp.data.status === "OK") {
          // this.createprogress = false;
          this.loaddataprogress = false;
          Toast.fire({
            icon: "success",
            title: "วาง",
          });
          this.fn_loadfileandfolder_scrolling_child();
        } else {
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: resp.data.errorMessage,
          });
        }
      } catch (err) {
        console.log(err);
        Toast.fire({
          icon: "error",
          title: err,
        });
      }
    },
    // ตั้ง thumbnail icon
    fn_setThumbnailIcon(_type) {
      let dataicon;
      let parameter;
      if (typeof _type === "string") {
        parameter = _type.toLowerCase();
      } else {
        parameter = _type;
      }
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "orange"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "primary"];
      } else if (parameter === "zip") {
        dataicon = ["mdi-zip-box", "blue-grey"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (parameter === "csv") {
        dataicon = ["mdi-file-table", "green"];
      } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png" || parameter === "gif") {
        dataicon = ["mdi-file-image", "blue-grey"];
      } else if(parameter === "avi" ||
  parameter === "mpeg" ||
  parameter === "mov" ||
  parameter === "mp4"||
  parameter === "mkv" ||
  parameter === "wmv" ||
  parameter === "3gp" ||
  parameter === "flv"){
    dataicon = ["mdi-file-video", "purple"];
  }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link","pink"]
    }
      else {
        dataicon = ["mdi-file", "black"];
      }
      return dataicon;
    },
    // โหลดรูป thumbnail มาแสดง
    async fn_loadthumbnail(_file_id, _file_type) {
      // this.filearraybuffer = "data:" + mimetype + ";base64," + b64encoded;
      let auth = await gbfGenerate.generateToken();
      let getThumbnail = "";
      if (_file_type === "png" || _file_type === "jpg" || _file_type === "jpeg" || _file_type == "bmp" || _file_type === "gif") {
        this.axios
          .get(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/thumbnail?file_id=" + _file_id, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            // console.log('response thumbnail',response.data)
            if (response.data.status === "OK") {
              let strThumbnail = response.data.base64_img;
              // console.log('thumbnail',"data:image/" + _file_type + ";base64," + strThumbnail)
              // let default_type = 'jpg'
              getThumbnail = "data:image/" + _file_type + ";base64," + strThumbnail;
              this.rootfile.filter((o) => o.file_id === _file_id).forEach((o) => (o.file_thumbnail = getThumbnail));
              this.filesLastView.filter((o) => o.file_id === _file_id).forEach((o) => (o.file_thumbnail = getThumbnail));
            }
          });
      }
    },
    //ฟังก์ชั่นกลับไปแหล่งที่มา
    gotoparent(parameter) {
      console.log("parameterparent", parameter);
      //parent folder
      if (this.dataAccountActive.type === "business") {
        this.parentidfile = parameter.folder_id;
        console.log(this.parentidfile);
        if (this.parentidfile === this.dataAccountActive["directory_id"]) {
          //  console.log("เข้า1");
          console.log("this.parentidfile ", this.parentidfile);
          console.log("this.dataAccountActiv", this.dataAccountActive["directory_id"]);
          this.rootfile = [];
          this.buff = [];
          this.$router.replace("/mydrive");
          this.fn_loadfileandfolder_scrolling_child();
        } else {
          if (this.parentidfile === "" || this.parentidfile === null || this.parentidfile === undefined) {
            // console.log("เข้า2");
            console.log("this.parentidfile", this.parentidfile);
            this.noparent = this.$route.params.id;
            this.noparent_M = this.dataAccountActive.business_info.my_folder_id;
            this.loadingdata = true;
            this.rootfile = [];
            this.buff = [];
            this.$router.push({ path: "/directory/" + this.dataAccountActive["directory_id"] });
            this.fn_loadfileandfolder_scrolling_child();
          } else {
            console.log("เข้า3");
            this.rootfile = [];
            this.buff = [];
            this.$router.push({ path: "/directory/" + this.parentidfile });
            // this.parentfolder = this.$route.params.id;
            this.fn_loadfileandfolder_scrolling_child();
          }
        }
      } else {
        this.parentidfile = parameter.file_parent_folder_id;
        if (this.parentidfile === "" || this.parentidfile === undefined || this.parentidfile === null) {
          this.parentidfile = parameter.folder_id;
        }
        if (this.parentidfile === this.dataAccountActive["directory_id"]) {
          // console.log("เข้า1");
          this.rootfile = [];
          this.buff = [];
          this.$router.replace("/mydrive");
          this.fn_loadfileandfolder_scrolling_child();
        } else {
          if (this.parentidfile === "" || this.parentidfile === null || this.parentidfile === undefined) {
            console.log("เข้า2");
            this.noparent = this.$route.params.id;
            this.noparent_M = this.dataAccountActive.business_info.my_folder_id;
            this.loadingdata = true;
            this.rootfile = [];
            this.buff = [];
            this.$router.push({ path: "/directory/" + this.dataAccountActive["directory_id"] });
            this.fn_loadfileandfolder_scrolling_child();
          } else {
            console.log("เข้า3");
            this.rootfile = [];
            this.buff = [];
            this.$router.push({ path: "/directory/" + this.parentidfile });
            // this.parentfolder = this.$route.params.id;
            this.fn_loadfileandfolder_scrolling_child();
          }
        }
      }
    },

    viewLog(message) {
      console.log("Message => ", message);
    },
    disableformulticlick(item) {
      // if(item === "Y"){
      //   if(this.filemultipledownload.length>1||this.statusmutipledownload==true){
      //     return true
      //   }
      // }else{
      //   return false
      // }
      // defalut
      return false;
    },
    clearparentvalue() {
      this.checkedList = [];
      this.filemultipledownload = [];
    },
    changeLocalStorage() {
      if (localStorage.getItem("changeStoreUI") === "true") {
        localStorage.setItem("changeStoreUI", "false");
        this.changeUI = false;
      } else if (localStorage.getItem("changeStoreUI") === "false") {
        localStorage.setItem("changeStoreUI", "true");
        this.changeUI = true;
      } else {
        console.log("localStore", localStorage.getItem("changeStoreUI"));
        localStorage.removeItem("changeStoreUI");
      }
    },
    removeCheckedListItem(item) {
      if (
        item ==
        this.filemultipledownload.find((i) => {
          return i == item;
        })
      ) {
        let updateFileMultipleDownload = this.filemultipledownload.filter((e) => e !== item);
        this.filemultipledownload = updateFileMultipleDownload;
        this.checkedList = updateFileMultipleDownload;
        console.log("UPDATE", this.filemultipledownload);
      } else {
        // ctrl+click เพิ่มค่า
        this.filemultipledownload.push(item);
        this.checkedList.push(item);
        if (item.status_lock_folder === "Y" || item.status_lock_file === "Y" || item.status_lock_data === 'Y') {
          this.filemultipledownload.pop();
          this.checkedList.pop();
        }
        console.log("UPDATE ADD", this.filemultipledownload);
      }
    },
    vBadgeIcon(item) {
      let stringClass = "";
      if (item.file_status_sharelink === "Y") {
        stringClass = "link";
      } else {
        stringClass = "";
      }
      if (item.file_status_share === "Y") {
        if (stringClass == "") {
          stringClass = "group_add";
        } else {
          stringClass += " group_add";
        }
      }
      if (item.status_updating === "") {
        if (stringClass == "") {
          stringClass = "check";
        } else {
          stringClass += " check";
        }
      } else {
        stringClass += "";
      }
      return stringClass;
    },
    resetFile() {
      if (this.statusmutipledownload == true) {
      } else {
        //แบบเดิมไม่มี if else
        this.filemultipledownload = [];
        this.checkedList = [];
      }
    },
    shiftSelect(item) {
      // คลิ๊ก item ที่ select ไว้ clear ค่าที่ไม่ได้ click ใน select ทั้งหมด
      if (this.statusmutipledownload == true) {
      } else {
        //แบบเดิมไม่มี if else
        this.resetFile();
        this.filemultipledownload.push(item);
        this.checkedList.push(item);
        console.log("Clear", this.filemultipledownload);
      }
    },
    selectColor(e, type) {
      if (type === "card") {
        if (this.filemultipledownload.length < 1) {
        } else {
          for (let i = 0; i < this.filemultipledownload.length; i++) {
            // if (e === this.filemultipledownload[i].file_name) {
            //   return "#D6EAF8";
            // }
            if (e === this.filemultipledownload[i].file_id) {
              return "#D6EAF8";
            }
          }
        }
      } else {
        if (this.filemultipledownload.length < 1) {
        } else {
          for (let i = 0; i < this.filemultipledownload.length; i++) {
            // if (e === this.filemultipledownload[i].file_name) {
            //   return "color:#2471A3 ";
            // }
            if (e === this.filemultipledownload[i].file_id) {
              return "color:#2471A3 ";
            }
          }
        }
      }
    },
    fn_handleDragSelectorChange(_checkedList) {
      console.log("Input:", _checkedList);
      this.choosecopy = [];
      // this.choosecopy = _checkedList;
      // เอาตัวซ้ำออก _checkedList เป็น parameter แค่รับมา filter ค่าซ้ำออก
      let newArrayFile = [...new Set(_checkedList)];
      // console.log('Output:',newArrayFile)
      // filter ครั้งแรกเอาพวกไฟล์บริษัทกับแผนกออก
      newArrayFile = newArrayFile.filter((element) => {
        return element.file_status != "M";
      });
      // fileter เอาค่าที่ไม่ต้องการออก
      if (newArrayFile.length > 1) {
        this.filemultipledownload = newArrayFile.filter((element) => {
          return (
            element.status_lock_folder !== "Y" && element.status_lock_file !== "Y" && element.file_permission.edit === "True" &&  element.status_lock_data !== 'Y'
          );
        });
      }
      this.choosecopy = this.filemultipledownload;
      // Toast แจ้งเตือน ------------------------------------------------------------------------------------------------------------------------------------------------------------
      // console.log("Output:", this.filemultipledownload);
      // // Toast ยิงไปบอกว่าบางไฟล์ที่คลุมไม่ขึ้น user นั้นไม่มีสิทธ์ หรือ ไฟล์มันล็อกอยู่
      // document.onmouseup=()=>{
      //   // Object.keys() ไว้เช็ค obj = {}
      //   if(Object.keys(this.filemultipledownload).length === 0 && this.checkedList.length > 1){
      //     console.log('CASE 1','filemultipledownload:',this.filemultipledownload.length,'checkedlist:',this.checkedList.length,'newArrayFile',newArrayFile.length)
      //     // กรณี มีไฟล์แต่ติด permission ทั้งหมด
      //     // filemultipledownload = {} | newArrayFile > 1 (มากกว่า 0 ไม่ได้เพราะมีกรณีคลิ๊กไฟล์เดียว)
      //     Toast.fire({
      //       icon: "warning",
      //       title: this.$t('toast.nopermissionfile'),
      //       // Some files you do not have permission to access
      //     });
      //     // แจ้งเตือนเสร็จให้เคลียค่าออก
      //     this.checkedList =[]
      //     newArrayFile = []
      //   } else if (Object.keys(this.filemultipledownload).length >= 1 && (Object.keys(this.filemultipledownload).length != newArrayFile.length) && newArrayFile.length != 0){
      //     // กรณีมีบางไฟล์ติด permission
      //     // filemultipledownload > 1 แต่มีบางตัว filter ออก | newArrayFile > 1
      //     // ดัก newArrayFile.length != 0 สำหรับเวลา dblclick เข้าโฟลเดอร์
      //     console.log('CASE 2','filemultipledownload:',this.filemultipledownload.length,'checkedlist:',this.checkedList.length,'newArrayFile',newArrayFile.length)
      //     Toast.fire({
      //       icon: "warning",
      //       title: this.$t('toast.nopermissionfile'),
      //       // Some files you do not have permission to access
      //     });
      //     // แจ้งเตือนเสร็จให้เคลียค่าออก
      //     this.checkedList =[]
      //     newArrayFile = []
      //   } else {
      //     console.log('else','filemultipledownload:',this.filemultipledownload.length,'checkedlist:',this.checkedList.length,'newArrayFile',newArrayFile.length)
      //     // filemultipledownload 0 checkList 0
      //   }
      //   // console.log('filemultipledownload length:',Object.keys(this.filemultipledownload).length)
      //   // console.log('newArrayFile length:',Object.keys(_checkedList).length)
      //   // console.log('checkedList length:',this.checkedList.length)
      // }
    },
    multideletetotrash() {
      if(this.filemultipledownload.length < 1){
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      }else{
        this.openchekmultidelete = true;
      // this.$store.dispatch("set_loading", true).then((res) => {});
        console.log("filemultipledownload", this.filemultipledownload);
        let datalist = [];
        this.status_data = "T";
      }
      // for (let i = 0; i < this.filemultipledownload.length; i++) {
      //   console.log(this.filemultipledownload[i]);
      //   if (this.filemultipledownload[i].file_type === "folder") {
      //     console.log("folder");
      //     datalist.push({
      //       data_id: this.filemultipledownload[i]["file_id"],
      //       data_type: this.filemultipledownload[i]["file_type"],
      //       data_status: "T",
      //     });
      //   } else {
      //     console.log("file");
      //     datalist.push({
      //       data_id: this.filemultipledownload[i]["file_id"],
      //       data_type: "file",
      //       data_status: "T",
      //     });
      //   }
      //}
      // let payload = {
      //   account_id: this.dataAccountId,
      //   user_id: this.dataUsername,
      //   data_list: datalist,
      //   // status_data:"T"
      // };
      // console.log("payload", payload);
      // this.axios
      //   .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/remove_multiples_file_folder_to_trash", payload)
      //   .then((response) => {
      //     console.log(response);
      //     if (response.data.status === "OK") {
      //       this.$store.dispatch("set_loading", false).then((res) => {});
      //       Toast.fire({
      //         icon: "success",
      //         title: this.$t("toast.multiset.remove"),
      //       });
      //       this.statusmutipledownload = false;
      //       this.clearmultipledownload();
      //       // this.loadfolder();
      //       this.fn_loadfileandfolder_scrolling_child();
      //       this.loadstorage();
      //     } else {
      //       Toast.fire({
      //         icon: "error",
      //         title: response.data.errorMessage,
      //       });
      //     }
      //   });
    },
    // ติดดาว multi file/folder
    async setmultiupdate(filemultipledownload, status) {
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        let count_item_nostar = 0;
        let count_item_star = 0;
        let status_star;
        let apifile;
        let apifolder;
        let payloadfile;
        let payloadfolder;
        let msgres;
        let filedata = [];
        let folderdata = [];
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          if (filemultipledownload[i].file_status !== status) {
            count_item_nostar++;
            if (filemultipledownload[i].file_type === "folder") {
              folderdata.push(this.filemultipledownload[i]["file_id"]);
            } else {
              filedata.push(this.filemultipledownload[i]["file_id"]);
            }
          } else {
            count_item_star++;
            console.log("repeat", filemultipledownload[i]);
            if (filemultipledownload[i].file_type === "folder") {
              folderdata.push(this.filemultipledownload[i]["file_id"]);
            } else {
              filedata.push(this.filemultipledownload[i]["file_id"]);
            }
          }
        }
        if (count_item_star == filemultipledownload.length) {
          console.log("nnnnnnnn");
          status_star = "N";
        } else if (count_item_nostar <= filemultipledownload.length) {
          console.log("ssssssss");
          status_star = "S";
        }
        payloadfolder = {
          list_folder: folderdata,
          account_id: this.dataAccountId,
          status_folder: status_star,
        };
        apifolder = "/api/multiple_update_status_folders";
        payloadfile = {
          list_file: filedata,
          account_id: this.dataAccountId,
          status_file: status_star,
        };
        apifile = "/api/multiple_update_status_files";

        console.log("folder", folderdata);
        console.log("file", filedata);
        try {
          let auth = await gbfGenerate.generateToken();
          let resultFile = await this.axios.post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + apifile, payloadfile, {
            headers: { Authorization: auth.code },
          });
          console.log("resultFile", resultFile);
          console.log("result =>", resultFile.data.result);
          let resultFolder = await this.axios.post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + apifolder, payloadfolder, {
            headers: { Authorization: auth.code },
          });
          console.log("resultFolder", resultFolder.status);
          console.log("result =>", resultFolder.data.result);
          if (resultFile.data.status == "OK" || resultFolder.data.status == "OK") {
            if (status_star === "S") {
              // "ติดดาวสำเร็จ";
              msgres = this.$t("toast.multiset.star");
              // } else if (status === "T") {
              //   // "นำออกสำเร็จ";
              //   msgres = this.$t("toast.multiset.remove");
            } else {
              // "ยกเลิกติดดาวสำเร็จ";
              msgres = this.$t("toast.multiset.cancle");
            }
            Toast.fire({
              icon: "success",
              title: msgres,
            });

            //OLD
            this.statusmutipledownload = false;
            this.clearmultipledownload();
            // this.loadfolder();
            this.fn_loadfileandfolder_scrolling_child();
            //NEW
            // this.statusmutipledownload = false;
            // this.clearmultipledownload();
            // this.loadfileandfolder();
          } else {
            console.log("ERROR");
            Toast.fire({
              icon: "error",
              title: resultFile.data.errorMessage || resultFolder.data.errorMessage,
            });
          }
        } catch (error) {
          console.log("result ERROR !!!", error);
          // แก้เลือกแต่ File await รอของ Folder ไม่โหลดค่าใหม่

          this.statusmutipledownload = false;
          this.clearmultipledownload();
          // this.loadfolder();
          this.fn_loadfileandfolder_scrolling_child();
          // ถ้าเอาออก มันจะไม่ update status ของตัว File
          if (status === "S") {
            // "ติดดาวสำเร็จ";
            msgres = this.$t("toast.multiset.star");
            // } else if (status === "T") {
            //   // "นำออกสำเร็จ";
            //   msgres = this.$t("toast.multiset.remove");
          } else {
            // "ยกเลิกติดดาวสำเร็จ";
            msgres = this.$t("toast.multiset.cancle");
          }
          Toast.fire({
            icon: "success",
            title: msgres,
          });
        }
      }
    },
    moveFileToFolder() {
      // โฟลเดอร์ปลายทาง
      // this.target_folder
      // ไฟล์ที่จะย้าย
      // this.holdfile_drag
      // payload and api
    },
    allowdrop(target) {
      // event.preventDefault()
    },
    drag(item) {
      // event.dataTransfer.setData("text", event)
      // console.log('drag ',event.dataTransfer.getData("text"))
      // ลากหลายไฟล์
      // this.checkedList
      // this.filemultipledownload
      // set file
      // this.holdfile_drag = []
      // if(this.filemultipledownload.length > 1){
      //   console.log('multi drag files',this.filemultipledownload)
      //   this.holdfile_drag = this.filemultipledownload
      // }else {
      //   console.log('Drag file',item.file_name)
      //   this.holdfile_drag.push(item)
      // }
      // let testrootfile = this.rootfile.filter((member) => {
      //   return member != item
      // })
      // console.log('move file to folder',testrootfile)
    },
    dropf(target) {
      // event.preventDefault()
      // console.log('Target folder',target)
      // console.log('before open dialog',this.open_confirmdialog)
      // // console.log('File drag',event.dataTransfer.getData('text'))
      // console.log('move file to',target,'\nitem',this.holdfile_drag)
      // this.target_folder = target
      // this.open_confirmdialog = true
    },
    dragenter(e) {
      document.querySelector("#dropzone").style.visibility = "";
      document.querySelector("#dropzone").style.opacity = 1;
      document.querySelector("#textnode").style.fontSize = "48px";
    },
    dragleave(e) {
      e.preventDefault();
      e.stopPropagation();
      document.querySelector("#dropzone").style.visibility = "hidden";
      document.querySelector("#dropzone").style.opacity = 0;
      document.querySelector("#textnode").style.fontSize = "42px";
    },
    dragover(e) {
      e.preventDefault();
      e.stopPropagation();
      document.querySelector("#dropzone").style.visibility = "";
      document.querySelector("#dropzone").style.opacity = 1;
      document.querySelector("#textnode").style.fontSize = "48px";
    },
    async drop(e) {
      e.preventDefault();
      e.stopPropagation();
      document.querySelector("#dropzone").style.visibility = "hidden";
      document.querySelector("#dropzone").style.opacity = 0;
      document.querySelector("#textnode").style.fontSize = "42px";
      //var filelist = document.querySelector('#filelist');
      // this.uploadFile(files);
      this.checkCCFolder = false;
      this.checkCC = false;
      // this.total = 0
      // this.count = 0
      let files = e.dataTransfer.files;
      this.folderupload = [];
      // console.log("eeeeeeeeeeeeeeeeeeeeee",e);
      console.log("Drop files:", files);
      //this.uploadfiles(files);
      //this.onDrop(files);
      // console.log(this.account_active["directory_id"]);
      var that = this;
      var that2 = await this;
      let parentfolder = "";
      let countt = 0;
      let total = 0;
      var beak = false;
      var traverseFileTreeCount = function self(item, path) {
        if (item.isFile) {
        } else if (item.isDirectory) {
          // Get folder contents
          var dirReader = item.createReader();
          dirReader.readEntries(async function(entries) {
            for (var i = 0; i < entries.length; i++) {
              that.total++;
              self(entries[i], path + item.name + "/");
            }
          });
        }
      };
      var traverseFileTree = function self(item, path) {
        if (that.checkCCFolder === true) {
          console.log("cancel");
          let objIndex = that.listDataUpload.findIndex((obj) => obj.name === that.rootname);
          that.listDataUpload[objIndex]["value"] = 500;
          that.listDataUpload[objIndex]["status"] = "Operation canceled by the user.";
          that.listDataUpload[objIndex]["color"] = "red";
        } else {
          path = path || "";
          if (item.isFile) {
            // Get file
            item.file(function(file) {});
          } else if (item.isDirectory) {
            // Get folder contents
            var dirReader = item.createReader();
            dirReader.readEntries(async function(entries) {
              for (var i = 0; i < entries.length; i++) {
                console.log(entries[i]);
                if (that.checkCCFolder === true) {
                  console.log("breakkkkkkkkkkk");
                  let objIndex = that.listDataUpload.findIndex((obj) => obj.name === that.rootname);
                  that.listDataUpload[objIndex]["value"] = 500;
                  that.listDataUpload[objIndex]["status"] = "Operation canceled by the user. ";
                  that.listDataUpload[objIndex]["color"] = "red";

                  beak = true;
                  break;
                } else {
                  countt++;
                  if (that.parentfolderDD !== "") {
                    await that.uploadfolderDD("l", entries[i], files, total, countt);
                  }
                  if (beak) {
                    console.log("breakkkkkkkkkkk");
                    break;
                  } else {
                    await self(entries[i], path + item.name + "/");
                  }
                }
              }
            });
          }
        }
      };
      let dataupload = [];
      var items = e.dataTransfer.items;

      for (let i = 0; i < files.length; i++) {
        if (files[i].type !== "") {
          dataupload.push(files[i]);
        }
      }
      this.onDrop(dataupload, "", "Fi", "");
      for (var i = 0; i < items.length; ++i) {
        //console.log("itemmmmmm",items[i]);
        //if (items[i].kind == 'file') {

        let item = items[i].webkitGetAsEntry();
        console.log("itemssssssssssssss");
        await traverseFileTreeCount(item);
        if (item.isFile) {
        } else if (item.isDirectory) {
          if (this.checkCCFolder === true) {
          } else {
            await this.uploadfolderDD("r", item);
            await traverseFileTree(item);
            // Toast.fire({
            //   icon: "error",
            //   title: "ยังไม่รองรับการอัปโหลดโฟลเดอร์",
            //  });
          }
        }
      }

      // let item = items[i].webkitGetAsEntry(function(entry) {
      // displayEntry(entry.name + (entry.isDirectory ? ' [dir]' : ''));
      // });
      //console.log(this.traverseFileTree(item));
      // }
    },
    async getFile(fileEntry) {
      try {
        return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
      } catch (err) {
        console.log(err);
      }
    },
    char_count(str, letter) {
      var letter_Count = 0;
      for (var position = 0; position < str.length; position++) {
        if (str.charAt(position) == letter) {
          letter_Count += 1;
        }
      }
      return letter_Count;
    },
    async uploadfolderDD(status, item, files, totalfolder, countfolder) {
      if (this.checkCCFolder === true) {
        console.log(this.checkCCFolder);
        let objIndex = this.listDataUpload.findIndex((obj) => obj.name === this.rootname);
        source.cancel("Operation canceled by the user.");
        this.listDataUpload[objIndex]["value"] = 500;
        this.listDataUpload[objIndex]["status"] = "Cancel upload folder by user";
        this.listDataUpload[objIndex]["color"] = "red";

        return;
      } else {
        console.log("totalfolder", this.total, countfolder);
        if (item.isFile) {
          let x = item.fullPath.search(item.name);
          let chpath = item.fullPath.slice(0, x - 1);
          let res1 = item.fullPath.split("/");
          //let res2 = res1[res1.length-1]
          let filepath = "";
          for (let i = 0; i < res1.length - 1; i++) {
            filepath += res1[i] + "/";
          }
          console.log(filepath, "weeeeeeeeeeeeeeeee");
          for (let i = 0; i < this.folderupload.length; i++) {
            //console.log("tttttt================================================",this.folderupload[i]["folder_path"]);
            if (filepath === this.folderupload[i]["folder_path"] + "/") {
              if (this.checkCCFolder === true) {
                let objIndex = this.listDataUpload.findIndex((obj) => obj.name === this.rootname);
                source.cancel("Operation canceled by the user.");
                this.listDataUpload[objIndex]["value"] = 500;
                this.listDataUpload[objIndex]["status"] = "Cancel upload folder by user";
                this.listDataUpload[objIndex]["color"] = "red";

                //
              } else {
                await this.getFile(item).then((res) => {
                  this.onDrop(res, this.folderupload[i]["folder_id"], "", this.rootFolder, totalfolder, countfolder);
                });
              }
              //  }
            }
          }
        } else if (item.isDirectory) {
          let x = item.fullPath.search(item.name);
          let res1 = item.fullPath.split("/");
          //let res2 = res1[res1.length-1]
          let chpath1 = "";
          for (let i = 0; i < res1.length - 1; i++) {
            chpath1 += res1[i] + "/";
          }
          let chpath = item.fullPath.slice(0, x - 1);

          console.log("wwwwwwwwwwwwww", chpath, chpath1, this.folderupload);
          for (let i = 0; i < this.folderupload.length; i++) {
            console.log(this.folderupload[i]["folder_path"], "dddd", chpath1);
            if (chpath1 === this.folderupload[i]["folder_path"] + "/") {
              console.log("checkpathhhhhh", chpath, chpath1, this.folderupload[i]["folder_path"]);
              this.parentfolderDD = this.folderupload[i]["folder_id"];
            }
          }
          if (status === "r") {
            let dataUpload = {};
            dataUpload["name"] = item.name;
            dataUpload["status"] = "กำลังอัปโหลด";
            dataUpload["value"] = 0;
            dataUpload["color"] = "green";
            dataUpload["type"] = "folder";
            dataUpload["icon"] = ["folder", "#FDD361"];
            dataUpload["showUpload"] = false;
            this.listDataUpload.push(dataUpload);
            // console.log(this.listDataUpload);

            var payload = {
              folder_name: item.name,
              user_id: this.dataUsername,
              id: this.account_active["directory_id"],
              account_id: this.dataAccountId,
              department_id: this.dataDepartmentAccessId,
            };
            //console.log("uploadheadfolder",payload);
            this.rootname = item.name;
          } else if (status === "l") {
            var payload = {
              folder_name: item.name,
              user_id: this.dataUsername,
              id: this.parentfolderDD,
              account_id: this.dataAccountId,
              department_id: this.dataDepartmentAccessId,
            };
          }
          let objIndex = this.listDataUpload.findIndex((obj) => obj.name === this.rootname);
          let CancelToken = this.axios.CancelToken;
          let source = new CancelToken.source();
          if (this.checkCCFolder === true) {
            let objIndex = this.listDataUpload.findIndex((obj) => obj.name === this.rootname);
            source.cancel("Operation canceled by the user.");
            this.listDataUpload[objIndex]["value"] = 500;
            this.listDataUpload[objIndex]["status"] = "Cancel upload folder by user";
            this.listDataUpload[objIndex]["color"] = "red";

            //
          }
          console.log("insertfolder", payload, this.parentfolderDD2);
          try {
            this.snackbar = true;
            let auth = await gbfGenerate.generateToken();
            let result = await this.axios({
              method: "POST",
              url: process.env.VUE_APP_SERVICE_UPLOAD_FOLDER + "/api/v2/insert_folder",
              cancelToken: source.token,
              data: payload,
              headers: { Authorization: auth.code },

              onUploadProgress: (e) => {
                //console.log(this.checkCC);

                if (this.checkCCFolder === true) {
                  source.cancel("Operation canceled by the user.");
                  this.listDataUpload[objIndex]["value"] = 500;

                  this.listDataUpload[objIndex]["status"] = "Operation canceled by the user.";
                  this.listDataUpload[objIndex]["color"] = "red";

                  //
                }

                // this.listDataUpload[objIndex]["value"] = 0;
                // this.listDataUpload[objIndex]["status"] = this.$t(
                //   "uploadFile.upload"
                // );
                // this.listDataUpload[objIndex]["color"] = "green";
              },
            });

            //this.axios.post(process.env.VUE_APP_SERVICE_UPLOAD_FOLDER + "/api/v2/insert_folder", payload).then(response => {
            let a = item.fullPath.substring(1);
            let b = a.search("/");
            //  console.log("-----------------b----------------",b);
            if (result.data.status === "OK") {
              if (b === -1) {
                this.count++;
                //this.re1 = this.char_count(a,'/');
                let x = item.fullPath.search(item.name);
                let datafolder = {};
                datafolder["folder_id"] = result.data.data.folder_id;
                this.rootFolder = item.name;
                datafolder["folder_path"] = item.fullPath;
                this.folderupload.push(datafolder);
                this.parentfolderDD = result.data.data.folder_id;
                let a = item.fullPath.substring(1);
                //console.log("reeeeeeeeeeeeeeeeee11111111111111",this.folderupload);
                //this.parentfolderDD2 = result.data.data.folder_id
                this.listDataUpload[objIndex]["value"] = parseInt((100 / this.total) * this.count);
                //this.listDataUpload[objIndex]["status"] = "กำลังอัปโหลด";
                //this.listDataUpload[objIndex]["color"] = "green";
                console.log("========= root success ========", this.count);
              } else {
                this.count++;
                this.parentfolderDD2 = result.data.data.folder_id;
                let x = item.fullPath.search(item.name);
                let datafolder = {};
                datafolder["folder_id"] = result.data.data.folder_id;
                datafolder["folder_path"] = item.fullPath;
                this.folderupload.push(datafolder);

                if (this.total - this.count === 0 || this.total - this.count === 1) {
                  //this.count++
                  this.listDataUpload[objIndex]["value"] = 100;
                  this.listDataUpload[objIndex]["status"] = "อัปโหลดสำเร็จ";
                  // this.listDataUpload[objIndex]["color"] = "green";
                  console.log("========= last success ========", this.count);
                  // this.loadfolder()
                  this.fn_loadfileandfolder_scrolling_child();
                } else {
                  this.listDataUpload[objIndex]["value"] = parseInt((100 / this.total) * this.count);
                  // this.listDataUpload[objIndex]["status"] = "กำลังอัปโหลด";
                  // this.listDataUpload[objIndex]["color"] = "green";
                  // this.count++;
                  console.log("========= other success ========", this.count, this.total, this.folderupload);
                }
              }
            } else {
              this.listDataUpload[objIndex]["value"] = 500;
              this.listDataUpload[objIndex]["status"] = result.data.errorMessage;
              this.listDataUpload[objIndex]["color"] = "red";
              // Toast.fire({
              //   icon: 'error',
              //   title: result.data.errorMessage
              // })
            }
          } catch (err) {
            // check if the request was cancelled
            if (this.axios.isCancel(err)) {
              // console.log(err.message);
              //this.checkCCFolder = false;
              // console.log(this.checkCC);
            }

            //console.log("ยกเลิกอัปโหลดไม่ทันอะ");
          }
        }
      }
    },

    removeEvent() {
      console.log("close");
      // var self = this;
      // self.closeDragDrop = true;
      // window.removeEventListener("dragenter", self.dragenter, true);
      // window.removeEventListener("dragleave", self.dragleave, true);
      // window.removeEventListener("dragover", self.dragover, true);
      // window.removeEventListener("drop", self.drop, true);
      this.removeevent = true;
      console.log(this.removeevent);
    },
    addEvent() {
      if (this.resolutionScreen >= 500) {
        console.log("open");
        // if (this.resolutionScreen >= 500) {
        //   var self = this;
        //   self.closeDragDrop = false;
        //   window.addEventListener("dragenter", self.dragenter, true);
        //   window.addEventListener("dragleave", self.dragleave, true);
        //   window.addEventListener("dragover", self.dragover, true);
        //   window.addEventListener("drop", self.drop, true);
        // }
        this.removeevent = false;
        console.log(this.removeevent);
      }
    },
    checkSort() {
      // this.loadfolder();
      this.rootfile = this.buff;
      this.clickSort = false;
      this.multiSort = false;
      console.log("this.buff;",this.buff);
    },
    sortFile(parameter) {
      this.folderSort = [];
      this.fileSort = [];
      this.sortdata = [];
      this.rootfile = [];
      // this.buff = [];
      let i, j;
      for (i = 0; i < parameter.length; i++) {
        if (parameter[i].file_type === "folder") {
          this.folderSort.push(parameter[i]);
        } else this.fileSort.push(parameter[i]);
      }
      if (this.sortby === "name") {
        if (this.multiSort === true) {
         // var collator_folder = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
          //console.log(collator_folder);
          // this.folderSort.sort(function(a, b) {
          //   return a.file_name.localeCompare(b.file_name);
          // });
          //this.compareObject(this.folderSort);
          this.folderSort = this.compareObject(this.folderSort);
          //var collator_file = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
          // this.fileSort.sort(function(a, b) {
          //   return a.file_name.localeCompare(b.file_name);
          // });
          //console.log(this.folderSort);
          this.fileSort = this.compareObject(this.fileSort);
        } else {
          this.folderSort.sort(function(a, b) {
            return b.file_name.localeCompare(a.file_name);
          });
          this.fileSort.sort(function(a, b) {
            return b.file_name.localeCompare(a.file_name);
          });
        }
      } else if (this.sortby === "date") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.file_lastdtm.localeCompare(b.file_lastdtm);
          });
          this.fileSort.sort(function(a, b) {
            return a.file_lastdtm.localeCompare(b.file_lastdtm);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.file_lastdtm.localeCompare(a.file_lastdtm);
          });
          this.fileSort.sort(function(a, b) {
            return b.file_lastdtm.localeCompare(a.file_lastdtm);
          });
        }
      } else if (this.sortby === "size") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return b.file_size - a.file_size;
          });
          this.fileSort.sort(function(a, b) {
            return b.file_size - a.file_size;
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return a.file_size - b.file_size;
          });
          this.fileSort.sort(function(a, b) {
            return a.file_size - b.file_size;
          });
        }
      } else if (this.sortby === "owner") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.file_owner_eng.localeCompare(b.file_owner_eng);
          });
          this.folderSort.sort(function(a, b) {
            return a.file_owner_th.localeCompare(b.file_owner_th);
          });
          this.fileSort.sort(function(a, b) {
            return a.file_owner_eng.localeCompare(b.file_owner_eng);
          });
          this.fileSort.sort(function(a, b) {
            return a.file_owner_th.localeCompare(b.file_owner_th);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.file_owner_eng.localeCompare(a.file_owner_eng);
          });
          this.folderSort.sort(function(a, b) {
            return b.file_owner_th.localeCompare(a.file_owner_th);
          });
          this.fileSort.sort(function(a, b) {
            return b.file_owner_eng.localeCompare(a.file_owner_eng);
          });
          this.fileSort.sort(function(a, b) {
            return b.file_owner_th.localeCompare(a.file_owner_th);
          });
        }
      }
      this.sortdata.push(this.folderSort);
      this.sortdata.push(this.fileSort);
      for (i = 0; i < this.sortdata.length; i++) {
        for (j = 0; j < this.sortdata[i].length; j++) {
          this.rootfile.push(this.sortdata[i][j]);
          //this.buff.push(this.sortdata[i][j]);
        }
      }
      return this.rootfile;
    },
    compareObject(item){
       let arr_name =[]
       let arr_name_result =[]
       for(let i = 0 ; i<item.length ; i++){
         arr_name.push(item[i]["file_name"])
       }
       var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
        arr_name.sort(collator.compare)      
        console.log("arr_name",arr_name); 
        
        for(let i = 0 ; i<arr_name.length ; i++){
          for(let j = 0 ; j<item.length ; j++){
            if(arr_name[i] === item[j]["file_name"]){
            arr_name_result.push(item[j])
          }
          }
          
        }
        console.log("arr_name_result",arr_name_result);

        return arr_name_result
    },
    clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
      this.checkedList = [];
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },

    //ดาวน์โหลดหลายไฟล์
    multipledownload() {
      // this.percentCompleted = 0;
      // var d = new Date();
      // this.datetime =
      //   d.toISOString().slice(0, 10) +
      //   " " +
      //   "at" +
      //   " " +
      //   this.zeroPadding(d.getHours(), 2) +
      //   "-" +
      //   this.zeroPadding(d.getMinutes(), 2) +
      //   "-" +
      //   this.zeroPadding(d.getSeconds(), 2);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        console.log("ไฟล์ที่เลือก", this.filemultipledownload);
        this.opendialogcheckmultidownload = true;
        // console.log("ไฟล์ที่เลือก",this.filemultipledownload);
        // let filedata = [];
        // for (let i = 0; i < this.filemultipledownload.length; i++) {
        //   if (this.filemultipledownload[i].file_type === "folder") {
        //     filedata.push({
        //       id: this.filemultipledownload[i]["file_id"],
        //       type: this.filemultipledownload[i]["file_type"],
        //     });
        //   } else {
        //     filedata.push({
        //       id: this.filemultipledownload[i]["file_id"],
        //       type: "file",
        //     });
        //   }
        // }
        // console.log("fileeeeeedataa", filedata);

        // let payload = {
        //   list: filedata,
        //   account_id: this.dataAccountId,
        // };
        // console.log("payyyyyloaaaad", payload);

        // // this.statusmutipledownload = true;
        // this.opensnackbarmultidownload = true;
        // this.axios
        //   .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/download_multiples_all", payload, {
        //     onDownloadProgress: (progressEvent) => {
        //       // console.log(progressEvent);
        //       let progresspercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        //       // console.log(progresspercent);
        //       if (progresspercent >= 95) {
        //         this.percentCompleted = 100;
        //       } else {
        //         this.percentCompleted = progresspercent;
        //       }
        //       // console.log("this.percentCompleted",this.percentCompleted);
        //       this.percent = this.percentCompleted;
        //     },
        //     withCredentials: false,
        //     responseType: "arraybuffer",
        //   })
        //   .then((response) => {
        //     if (response.statusText === "OK") {
        //       this.percentCompleted = 100;
        //       console.log(response);
        //     }
        //     const blob = new Blob([response.data]);
        //     const content = response.headers["content-type"];
        //     saveAs(blob, this.datetime + ".zip");
        //     this.clearmultipledownload();
        //     // this.statusmutipledownload = false;
        //     this.opensnackbarmultidownload = false
        //   })
        //   .catch((error) => {
        //     this.opensnackbarmultidownload = false;
        //     // this.statusmutipledownload = false;
        //     this.$store.dispatch("show_loading", false);
        //     Toast.fire({
        //       icon: "error",
        //       title: this.$t("myinboxPage.msg.apierror"),
        //     });
        //     console.log(error);
        //   });
      }
    },

    filteredItems(search) {
      this.disablescrolling = true;
      if (search !== "") {
        this.rootfile = this.buff;
        let filldata = this.rootfile.filter((item) => {
          return item.file_name.toLowerCase().indexOf(search.toLowerCase()) > -1 && item.file_type === "folder";
        });
        this.rootfile = [];
        filldata.forEach((filedata) => {
          this.rootfile.push(filedata);
        });
      } else {
        this.rootfile = this.buff;
      }
    },

    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === "-" || _datetime === undefined || _datetime === null) {
        return "-";
      } else {
        let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute + ":" + second;
      }
    },

    onChildClick(value) {
      console.log(value);
    },

    sendParentfolder() {
      // if(this.$route.params.id === undefined){
      //   this.parentfolder = ''
      // }else{
      //   this.parentfolder = this.$route.params.id
      // }

      this.parentfolder = this.account_active["directory_id"];
      // console.log(this.parentfolder);
    },

    //ลบไฟล์ โฟลเดอร์
    async deletenewfunction(parameter) {
      let payload = {
        account_id: parameter.account_sender,
        user_id: this.dataUsername,
        data_id: parameter.file_id,
        data_type: parameter.file_type === "folder" ? "folder" : "file",
        status_data: "T",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/remove_data_to_trash", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("delresponse", response);
            Toast.fire({
              icon: "success",
              title: this.$t("toast.text.remove") + parameter.file_name + " " + this.$t("toast.text.totrash"),
            });
            // this.loadfolder();
            this.fn_loadfileandfolder_scrolling_child();
            this.loadstorage();
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },

    // ตั้งไฟล์ติดดาว
    async setPermissionFileStar(parameter, status) {
      let api;
      let payload;
      let msgres;
      console.log("status", parameter);
      if (status === "T") {
        // this.opensdialogcheckdeleterightclick = true;
        this.deletenewfunction(parameter);
      } else {
        console.log("restore+++++++++++++++++");
        if (parameter.file_type === "folder") {
          payload = {
            folder_id: parameter.file_id,
            folder_name: "",
            status_folder: status,
            move_to_id: "",
            account_id: parameter.account_sender,
            user_id: this.dataUsername,
          };
          api = "/api/v2/update_folder_id";
        } else if (parameter.file_type !== "folder") {
          payload = {
            file_id: parameter.file_id,
            filename: "",
            folder_id: "",
            status_file: status,
            account_id: parameter.account_sender,
            user_id: this.dataUsername,
          };
          api = "/api/v2/update_file_id";
        }

        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload, { headers: { Authorization: auth.code } })
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              if (status === "S") {
                // "ติดดาว " + parameter.file_name + " สำเร็จ"
                msgres = this.$t("toast.text.starred") + parameter.file_name + this.$t("toast.text.success");
              }
              // else if (status === "T") {
              //   // "นำ " + parameter.file_name + " ออกสำเร็จ"
              //   msgres =
              //     this.$t("toast.text.remove") +
              //     parameter.file_name +
              //     this.$t("toast.text.success");
              // }
              else {
                // "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ"
                msgres = this.$t("toast.text.canclestart") + parameter.file_name + this.$t("toast.text.success");
              }

              Toast.fire({
                icon: "success",
                title: msgres,
              });
              // this.$emit('closecurrent')
              // this.loadfolder();
              this.fn_loadfileandfolder_scrolling_child();
              this.confirmdelete = false;

              // this.loadfileandfolder();
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Toast.fire({
              icon: "error",
              // "ไม่สามารถติดดาว " + parameter.file_name + " ได้"
              title: this.$t("toast.text.cannotstar") + parameter.file_name + this.$t("toast.text.textth"),
            });
          });
      }
    },

    appsbarmultimovefile(e) {
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        e.preventDefault();
        this.x = e.clientX;
        this.y = e.clientY;
        this.sendParentfolder();
        this.opendialogmultimove = true;
      }
    },

    rightclickfileandfolder(e, filename) {
      if (this.filemultipledownload.length > 1) {
        this.rightclickmultifile = true;
      }
      e.preventDefault();
      this.sendParentfolder();
      this.showcurrentMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = false;
      this.$nextTick(() => {
        this.currentfile = filename;
        this.showcurrentMenu = true;
        if (this.showMenu === true) {
          this.showcurrentMenu = false;
        } else if (this.rightclickmultifile === true) {
          this.showcurrentMenu = false;
        } else {
          setTimeout(() => {
            this.showcurrentMenu = true;
          }, 300);
        }
      });
    },

    // เข้าไปที่ โฟลเดอร์
    gotodirectory(folderid, type, departmentid, file, item) {
      // ให้ reset ค่าของ filemultipledownload กับ checklist เวลากดเม้า Toast จะได้ไม่แจ้งเตือน
      this.resetFile();
      // if (file.file_type === "folder") {
      //   console.log("department_access_id", file.file_department);
      // this.$store.dispatch("department_access_id", file.file_department);
      // this.$router.push({ path: `/directory/` + `${file.file_id}` });
      // this.$router.replace({ path: "/directory/" + file.file_id });
      // const destination = file.file_id
      // this.$router.push({ name: 'directory', params: { destination  } })
      // this.loadfolder();
      // } else {
      //   this.currentfile = file;
      //   this.openpreviewfile = true;
      // }
      // console.log("folder",file);
      // if(file.status_lock_folder === 'Y'&& type === 'folder' || file.status_lock_file === 'Y' && type !== 'folder'){
      //   this.opendialog_checklog = true
      //   this.newdata = file
      //   console.log("newdata",this.newdata);
      // }else{
      this.checkgotodirectory(file, item);
      // }else{
      //   console.log("file",file);
      //   this.checkgotodirectory(file)
      // }
    },

    checkgotodirectory(file, item) {
      if (file.file_type === "folder") {
        this.$store.dispatch("department_access_id", file.file_department);
        this.$router.push({ path: "/directory/" + file.file_id });
        // this.loadfolder();
      } else {
        this.currentfile = file;
        // this.newdata = file
        this.openpreviewfile = true;
        //this.arrayfile_lastview = item
        this.arrayfile = item;
      }
    },

    // พรีวิวไฟล์
    newpreviewfile(file) {
      // console.log(file);
      if (file.file_type !== "folder") {
        this.currentfile = file;
        this.opennewpreviewfile = true;
        console.log("open", this.opennewpreviewfile);
      }
    },

    show(e) {
      e.preventDefault();
      this.sendParentfolder();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        if (this.showcurrentMenu === true || this.rightclickmultifile === true) {
          this.showMenu = false;
        } else {
          setTimeout(() => {
            this.showMenu = true;
          }, 300);
        }
      });
    },

    cancelAll() {
      this.snackbar = false;
      this.checkCCFolder = true;
      this.checkCC = true;
      //console.log("test");
      this.total = 0;
      this.count = 0;
      // this.loadfolder();
      this.fn_loadfileandfolder_scrolling_child();
    },

    cancel(item) {
      this.itemC = item;
      console.log("this.itemC", this.itemC);
      var res = this.itemC["name"].split(".");
      if (res[1] === undefined) {
        this.checkCCFolder = true;
        //console.log("test");
        let objIndex = this.listDataUpload.findIndex((obj) => obj.name === this.rootname);
        this.listDataUpload[objIndex]["value"] = 500;
        this.listDataUpload[objIndex]["status"] = "Operation canceled by the user.";
        this.listDataUpload[objIndex]["color"] = "red";
      } else {
        this.checkCC = true;
        console.log("this.checkCC", this.checkCC);
      }
    },

    onDrop(files, folderid, status, rootfolder, totalfolder, countfolder) {
      console.log("status", status);
      if (status === "Fi") {
        this.listDataUpload = [];
        for (let i = 0; i < files.length; i++) {
          let dataUpload = {};
          var res = files[i].name.split(".");
          console.log("files[i].name", files[i].name);
          console.log("res", res[1]);
          dataUpload["name"] = files[i].name;
          dataUpload["status"] = "กำลังอัปโหลด";
          dataUpload["value"] = 0;
          dataUpload["color"] = "green";
          dataUpload["icon"] = gb_setIconFiles.iconExtensionFilesOnly_new(res[1]);
          dataUpload["showUpload"] = false;
          this.listDataUpload.push(dataUpload);
          console.log("filesssssssssssssssssss");
          //this.listDataUpload = this.listDataUpload.filter(item => item !== this.itemC)
          this.uploadfiles(files[i]);
        }
        // console.log(this.listDataUpload);
      } else {
        this.uploadfilesFolder(files, folderid, rootfolder, totalfolder, countfolder);
      }
    },

    async uploadfilesFolder(files, folderid, rootfolder, totalfolder, countfolder) {
      if (this.checkCCFolder === true) {
        console.log(this.checkCCFolder);
        let objIndex = this.listDataUpload.findIndex((obj) => obj.name === rootfolder);
        source.cancel("Operation canceled by the user.");
        this.listDataUpload[objIndex]["value"] = 500;
        this.listDataUpload[objIndex]["status"] = "Cancel upload folder by user";
        this.listDataUpload[objIndex]["color"] = "red";
        return;
      } else {
        console.log("uploadfilesFolder", totalfolder, countfolder);

        let objIndex = this.listDataUpload.findIndex((obj) => obj.name === rootfolder);

        const formData = new FormData();

        //formData.append("file", files[i]);
        formData.append("file", files);
        //formData.append("filname", files[i].name);
        formData.append("size_file", files.size);
        //formData.append("size_file", files[i].size);

        formData.append("folder_id", folderid);
        //console.log(folderid);

        formData.append("user_id", this.dataUsername);
        formData.append("department_id", this.dataDepartmentAccessId);
        formData.append("account_id", this.dataAccountId);
        //console.log("hover2",formData);
        // the request itself
        //if (files[i].size >= 500000000) {
        if (files.size >= 2147483648) {
          this.listDataUpload[objIndex]["value"] = 500;
          this.listDataUpload[objIndex]["status"] = "size file more than 1GB";
          this.listDataUpload[objIndex]["color"] = "red";
        } else {
          let CancelToken = this.axios.CancelToken;
          let source = new CancelToken.source();
          //  console.log("lklklklklkl", source);
          // console.log(this.checkCC);
          if (this.checkCCFolder === true) {
            source.cancel("Operation canceled by the user.");
            this.listDataUpload[objIndex]["value"] = 500;
            this.listDataUpload[objIndex]["status"] = "Cancel upload folder by user";
            this.listDataUpload[objIndex]["color"] = "red";

            //
          }
          try {
            let auth = await gbfGenerate.generateToken();
            this.snackbar = true;
            let result = await this.axios({
              method: "POST",
              url: process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/upload_files",
              cancelToken: source.token,
              data: formData,
              headers: { Authorization: auth.code },

              onUploadProgress: (e) => {
                //console.log(this.checkCC);
                if (this.checkCCFolder === true) {
                  source.cancel("Operation canceled by the user.");
                  this.listDataUpload[objIndex]["value"] = 500;
                  this.listDataUpload[objIndex]["status"] = "Cancel upload folder by user";
                  this.listDataUpload[objIndex]["color"] = "red";

                  //
                }
              },
            });
            // console.log("result", result);
            if (result.data.status === "OK") {
              if (this.total - this.count === 0) {
                //this.count--;
                this.listDataUpload[objIndex]["value"] = 100;
                this.listDataUpload[objIndex]["status"] = result.data.message;
                this.listDataUpload[objIndex]["color"] = "green";
                // this.loadfolder()
                this.fn_loadfileandfolder_scrolling_child();
              } else {
                this.listDataUpload[objIndex]["value"] = parseInt((100 / this.total) * this.count);
                // this.listDataUpload[objIndex]["status"] = "กำลังอัปโหลด";
                // this.listDataUpload[objIndex]["color"] = "green";
                this.count++;
                //console.log(this.count);
              }
            } else if (result.data.status === 413) {
              this.listDataUpload[objIndex]["value"] = 500;
              this.listDataUpload[objIndex]["status"] = result.data.errorMessage;
              this.listDataUpload[objIndex]["color"] = "red";
            } else if (result.data.errorCode === "ER999") {
              this.listDataUpload[objIndex]["value"] = 500;
              this.listDataUpload[objIndex]["status"] = "Can not upload";
              this.listDataUpload[objIndex]["color"] = "red";
            } else {
              this.listDataUpload[objIndex]["value"] = 500;
              this.listDataUpload[objIndex]["status"] = result.data.errorMessage;
              this.listDataUpload[objIndex]["color"] = "red";
            }
          } catch (err) {
            // check if the request was cancelled
            if (this.axios.isCancel(err)) {
              // console.log(err.message);
              // this.checkCCFolder = false;
              // console.log(this.checkCC);
            }

            //console.log("ยกเลิกอัปโหลดไม่ทันอะ");
          }
        }
      }
      // }
      // this.loadfolder();
      // this.$store
      //   .dispatch("check_storage", this.dataAccountId)
      //   .then((res) => {});
      // console.log("this.dataAccountId------", this.dataAccountId);
    },

    async uploadfiles(files, folderid, rootfolder) {
      // console.log("hover",folderid,rootfolder);
      let parent = this.sendParentfolder();
      //console.log("process start",parent);
      // formData.append('file', file, file.name);
      // for (let i = 0; i < files.length; i++) {
      let objIndex = this.listDataUpload.findIndex((obj) => obj.name === files.name);

      const formData = new FormData();

      //formData.append("file", files[i]);
      formData.append("file", files);
      //formData.append("filname", files[i].name);
      formData.append("size_file", files.size);
      //formData.append("size_file", files[i].size);
      if (folderid === undefined) {
        formData.append("folder_id", this.parentfolder);
        //console.log(this.parentfolderDD);
      } else {
        formData.append("folder_id", folderid);
        //console.log(folderid);
      }
      formData.append("user_id", this.dataUsername);
      formData.append("department_id", this.dataDepartmentAccessId);
      formData.append("account_id", this.dataAccountId);
      //console.log("hover2",formData);
      // the request itself
      //if (files[i].size >= 500000000) {
      if (files.size >= 2147483648) {
        this.listDataUpload[objIndex]["value"] = 500;
        this.listDataUpload[objIndex]["status"] = "size file more than 1GB";
        this.listDataUpload[objIndex]["color"] = "red";
      } else {
        let CancelToken = this.axios.CancelToken;
        let source = new CancelToken.source();
        //  console.log("lklklklklkl", source);
        console.log("this.checkCC", this.checkCC);
        try {
          let auth = await gbfGenerate.generateToken();
          this.snackbar = true;
          let result = await this.axios({
            method: "POST",
            url: process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/upload_files",
            cancelToken: source.token,
            data: formData,
            headers: { Authorization: auth.code },

            onUploadProgress: (e) => {
              //console.log(this.checkCC);
              console.log("this.checkCC", this.checkCC);
              if (this.checkCC === true) {
                source.cancel("Operation canceled by the user.");
                //console.log(result.data.errorMessage);
                this.listDataUpload[objIndex]["value"] = 500;
                this.listDataUpload[objIndex]["status"] = "canceled by the user.";
                this.listDataUpload[objIndex]["color"] = "red";

                //
              }

              // this.listDataUpload[objIndex]["value"] = 0;
              // this.listDataUpload[objIndex]["status"] = this.$t(
              //   "uploadFile.upload"
              // );
              //this.listDataUpload[objIndex]["color"] = "green";
              if ((e.loaded / e.total) * 100 >= 95) {
                this.listDataUpload[objIndex]["value"] = 95;
                this.timeout = (e.loaded / e.total) * 100000;
              } else {
                this.listDataUpload[objIndex]["value"] = parseInt((e.loaded / e.total) * 100);
              }
            },
          });
          console.log("result", result);
          if (result.data.status === "OK") {
            this.listDataUpload[objIndex]["value"] = 100;
            this.listDataUpload[objIndex]["status"] = result.data.message;
            this.listDataUpload[objIndex]["color"] = "green";
          } else if (result.data.status === 413) {
            this.listDataUpload[objIndex]["value"] = 500;
            this.listDataUpload[objIndex]["status"] = result.data.errorMessage;
            this.listDataUpload[objIndex]["color"] = "red";
          } else if (result.data.errorCode === "ER999") {
            this.listDataUpload[objIndex]["value"] = 500;
            this.listDataUpload[objIndex]["status"] = "Can not upload";
            this.listDataUpload[objIndex]["color"] = "red";
          } else {
            this.listDataUpload[objIndex]["value"] = 500;
            this.listDataUpload[objIndex]["status"] = result.data.errorMessage;
            this.listDataUpload[objIndex]["color"] = "red";
          }
        } catch (err) {
          // check if the request was cancelled
          if (this.axios.isCancel(err)) {
            console.log("eeererrreerrrrorrr", err.message);
            this.listDataUpload[objIndex]["status"] = err.message;
            this.checkCC = false;
            // console.log(this.checkCC);
          }

          //console.log("ยกเลิกอัปโหลดไม่ทันอะ");
        }
      }
      // }
      //  this.loadfolder();
      this.fn_loadfileandfolder_scrolling_child();
      // this.$store
      //   .dispatch("check_storage", this.dataAccountId)
      //   .then((res) => {});
      // console.log("this.dataAccountId------", this.dataAccountId);
    },

    async fulltextsearch(text) {
      this.disablescrolling = true;
      this.rootfile = [];
      this.buff = [];
      let payload = {
        text: text["keyword"],
        account_id: this.dataAccountId,
        type_search: text["type"],
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/full_text_search", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status == "OK") {
            for (let i = 0; i < response["data"]["data"].length; i++) {
              let datafolder = {};
              datafolder["editing_status"] = response.data.data[i]["editing_status"];
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["file_id"] = response.data.data[i]["file_id"];
              datafolder["file_name"] = response.data.data[i]["filename"];
              datafolder["file_size"] = response.data.data[i]["size_file"];
              datafolder["file_status"] = response.data.data[i]["status_file"];
              datafolder["file_owner_eng"] = "";
              datafolder["file_owner_th"] = "";
              datafolder["file_linkshare"] = "";
              datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
              datafolder["file_date"] = "";
              datafolder["file_permission"] = "";
              datafolder["file_status_share"] = "";
              datafolder["file_status_sharelink"] = "";
              datafolder["file_type"] = response.data.data[i]["data_type"];
              datafolder["file_department"] = "";
              datafolder["file_icon"] = gb_setIconFiles._new(response.data.data[i]["data_type"]);
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
            }
          } else {
            console.log(response.data);
          }
        });
    },

    //รับข้อมูลจาก toolbar
    fn_advancesearch(_param) {
      this.heardertextsearch = true;
      this.disablescrolling = true;
      this.rootfile = [];
      this.buff = [];
      const datafolder = gb_advanceSearch.setDataCompleteAdvanceSearch(_param, this.dataAccountId);
      console.log('datafolder',datafolder);
      let count_file = datafolder.filter((item) => {
        return item.file_type === "file";
      });
      let count_folder = datafolder.filter((item) => {
        return item.file_type === "folder";
      });
      this.totaldata = {
        totalall: count_file.length + count_folder.length,
        totalfile: count_file.length,
        totalfolder: count_folder.length,
      };
      this.rootfile = datafolder;
      for (let index = 0; index < this.rootfile.length; index++) {
        const element = this.rootfile[index];
        element.file_thumbnail = "";
        let typeFile = element.file_name.split(".");
        this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1]);
      }
      this.buff = datafolder;
      this.filemultipledownload = [];
    },

    // โหลดข้อมูล โฟลเดอร์
    async loadfolder() {
      this.removeEvent();

      this.addEvent();
      this.rootfile = [];
      this.buff = [];

      if (this.dataAccountActive["type"] === "Business") {
        console.log("Business Login => ", this.dataAccountActive);
        let datafolder = {};
        datafolder["file_id"] = this.dataAccountActive["business_info"]["my_folder_id"];
        datafolder["file_name"] = this.$store.getters.dataTypeusercurrent(
          this.$store.state.account_active["type"],
          this.$t("default")
        );
        datafolder["file_lastdtm"] = "-";
        datafolder["file_owner_eng"] = "-";
        datafolder["file_owner_th"] = "-";
        datafolder["file_size"] = "-";
        datafolder["file_department"] = "";
        datafolder["file_status"] = "M";
        datafolder["file_type"] = "folder";
        datafolder["file_icon"] = ["mdi-folder-network", "blue darken-4"];
        this.rootfile.push(datafolder);
        this.buff.push(datafolder);
      }
      if (this.clickSort === true) {
        this.rootfile.push(this.sortFile(this.rootfile));
      }
      var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.account_active["directory_id"],
        status_folder: "",
      };
      // console.log(payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FOLDER + "/api/v2/search_folder_byid", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log("Apifolder", response);
          // console.log("count",response.data.folder.length);
          if (response.data.status === "OK") {
            this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            this.aspectData = [
              {
                aspect: response.data.your_folder[0]["aspect"],
                aspect_key: response.data.your_folder[0]["aspect_key"],
              },
            ];
            // console.log("api aspect data", this.aspectData);
            // this.rootfile = response.data.folder
            // if (response.data.folder.length) {
            //   this.page += 1;
            //   this.rootfile.push(...response.data.folder);
            //   $state.loaded();
            // } else {
            //   $state.complete();
            // }
            var i;
            let orderfolder = response.data.folder;
            for (i = 0; i < response.data.folder.length; i++) {
              let datafolder = {};
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["file_id"] = response.data.folder[i]["folder_id"];
              datafolder["file_name"] = response.data.folder[i]["folder_name"];
              datafolder["file_linkshare"] = response.data.folder[i]["link"];
              datafolder["file_status_share"] = response.data.folder[i]["status_share"];
              datafolder["file_status_sharelink"] = response.data.folder[i]["status_share_link"];
              datafolder["file_size"] = "-";
              datafolder["file_date"] = "";
              datafolder["status_lock_folder"] = response.data.folder[i]["status_lock_folder"];
              datafolder["file_lastdtm"] = response.data.folder[i]["cre_dtm"];
              datafolder["file_department"] = "";
              datafolder["file_owner_eng"] = response.data.folder[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.folder[i]["name_th"];
              datafolder["parent_folder_id"] = response.data.folder[i]["parent_folder_id"];
              datafolder["file_permission"] = response.data.folder[i]["permission_account"];
              datafolder["file_permission_2"] = response.data.folder[i]["permission_account_v2"];
              datafolder["permission_department_setting"] = response.data.folder[i]["permission_department_setting"];
              datafolder["permission_setting"] = response.data.folder[i]["permission_setting"];
              datafolder["file_status"] = response.data.folder[i]["status"];
              datafolder["file_type"] = "folder";
              datafolder["file_icon"] = ["folder", "#FDD361"];
              datafolder["status_updating"] = response.data.folder[i]["status_updating"];
              datafolder["full_path"] = response.data.folder[i].full_path;
              datafolder["status_updating"] = response.data.folder[i].status_updating;
              datafolder["editing_status"] = response.data.data[i]["editing_status"];
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
            }
            // console.log("rootfoldermydrive",this.rootfile);
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
            this.loadfiles(this.account_active["directory_id"], "");
            this.countfolder = response.data.folder.length;
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },

    // โหลดข้อมูลไฟล์
    async loadfiles(folder, status) {
      console.log("dd", this.dataAccountActive);
      var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: folder,
        status_file: status,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v2/search_file", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("apifile", response);
            var i;
            for (i = 0; i < response.data.data.length; i++) {
              let datafolder = {};
              let typefile = response.data.data[i]["filename"].split(".");
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["file_id"] = response.data.data[i]["id"];
              datafolder["file_name"] = response.data.data[i]["filename"];
              datafolder["file_size"] = response.data.data[i]["size_file"];
              datafolder["file_status"] = response.data.data[i]["status_file"];
              datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.data[i]["name_th"];
              datafolder["file_linkshare"] = response.data.data[i]["link"];
              datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
              datafolder["status_lock_file"] = response.data.data[i]["status_lock_file"];
              datafolder["file_date"] = "";
              datafolder["file_permission"] = response.data.data[i]["permission_account"];
              datafolder["file_permission_2"] = response.data.data[i]["permission_account_v2"];
              datafolder["permission_department_setting"] = response.data.data[i]["permission_department_setting"];
              datafolder["permission_setting"] = response.data.data[i]["permission_setting"];
              datafolder["file_status_share"] = response.data.data[i]["status_share"];
              datafolder["file_status_sharelink"] = response.data.data[i]["status_share_link"];
              datafolder["file_type"] = typefile[typefile.length - 1];
              datafolder["file_department"] = "";
              datafolder["status_updating"] = response.data.data[i]["status_updating"];
              datafolder["file_icon"] = gb_setIconFiles.iconExtensionFilesOnly(typefile[typefile.length - 1]);
              datafolder["full_path"] = response.data.data[i].full_path;
              datafolder["status_updating"] = response.data.data[i].status_updating;
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
            }
            console.log("rootfilemydeive->", this.rootfile);
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
            this.loaddataprogress = false;
            this.countfile = response.data.data.length;
            console.log("this.countfile-*-*-*-*", this.countfile);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเรียกไฟล์ได้ในขณะนี้"
            title: this.$t("toast.cannotopenfile"),
          });
        });
    },

    //แปลงขนาดไฟล์
    fn_calculatesize(_sizefilebyte) {
      return gb_sizeDataConverter.convertStorageToKbMbGb(_sizefilebyte);
    },

    //โหลดจำนวน Storage ที่ใช้ไปทั้งหมด
    loadstorage() {
      this.$store.dispatch("check_storage", this.dataAccountId).then((res) => {});
      this.$store.dispatch("check_color", localStorage.getItem("color")).then((res) => {});
    },

    changepage() {
      // this.loaddataprogress = true;
      // setTimeout(() => {
      //   this.loaddataprogress = false;
      // }, 1000);
    },

    async downloadfile(currentfile) {
      if (currentfile.file_type === "folder") {
        // Toast.fire({
        //   icon: "warning",
        //   title: "Coming Soon !!"
        // });
        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
          "/api/v2/download_folder?account_id=" +
          this.dataAccountId +
          "&folder_id=" +
          currentfile.file_id;

        const link = document.createElement("a");
        link.setAttribute("href", url); //or any other extension
        link.setAttribute("download", currentfile.file_name + ".zip"); //or any other extension
        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
          // Cleanup the DOM
          document.body.removeChild(link);
        }, 500);
      } else {
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: currentfile.file_id,
        };

        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v2/download_file?user_id=" +
          this.dataUsername +
          "&file_id=" +
          currentfile.file_id;
        // saveAs(link,currentfile.file_name)
        // download(link,currentfile.file_name, "application/pdf")

        // const link = document.createElement("a");
        // link.setAttribute("href", url); //or any other extension
        // link.setAttribute("download", currentfile.file_name); //or any other extension
        // document.body.appendChild(link);
        // // document.body.removeChild(link);
        // setTimeout(() => {
        //   link.click();
        //   // Cleanup the DOM
        //   document.body.removeChild(link);
        // }, 500);
        let auth = await gbfGenerate.generateToken();

        this.axios
          .get(url, {
            headers: { Authorization: auth.code },
            onDownloadProgress: (progressEvent) => {
              let progresspercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
              // console.log(percentCompleted);
              // alert(percentCompleted)
            },
            withCredentials: false,
            responseType: "arraybuffer",
          })
          .then((response) => {
            console.log(percentCompleted);
            const blob = new Blob([response.data]);
            // const content = response.headers['content-type'];
            // saveAs(blob,currentfile.file_name)
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              // "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
              title: this.$t("toast.cannotdownload") + currentfile.file_name + this.$t("toast.text.textth"),
            });
            console.log(error);
          });
      }

      // this.axios
      //   .post(process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_file", payload, {
      //     withCredentials: false,
      //     responseType: "blob",
      //   })
      //   .then(response => {
      //       const blob = new Blob([response.data]);
      //       const content = response.headers['content-type'];
      //       saveAs(blob,currentfile.file_name)
      //   })
      //   .catch(error => {
      //     Toast.fire({
      //       icon: "error",
      //       title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
      //     });
      //     console.log(error);
      //   });
      // }
    },
    async fn_loadfilelastview() {
      var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.account_active["directory_id"],
        status_file: "",
        status_folder: "",
        limit: 0,
        offset: 5,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/select_files_and_folder/last_view", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.filesLastView = [];
            for (let i = 0; i < response.data.data_last_view.length; i++) {
              // console.log("response++++++", response.data.data_last_view);
              let file_lastview = {};
              let typefile = response.data.data_last_view[i].name.split(".");
              let type = response.data.data_last_view[i].types;

              file_lastview["account_reciever"] = "";
              file_lastview["account_sender"] = this.dataAccountId;
              file_lastview["account_id"] = response.data.data_last_view[i].account_id;
              file_lastview["countnum"] = response.data.data_last_view[i].countnum;
              file_lastview["file_createdtm"] = response.data.data_last_view[i].cre_dtm;
              file_lastview["folder_id"] = response.data.data_last_view[i].folder_id;
              file_lastview["file_id"] = response.data.data_last_view[i].id;
              file_lastview["file_lastdtm"] = response.data.data_last_view[i].last_dtm;
              file_lastview["file_linkshare"] = response.data.data_last_view[i].link;
              file_lastview["file_name"] = response.data.data_last_view[i].name;
              file_lastview["file_owner_eng"] = response.data.data_last_view[i].name_eng;
              file_lastview["file_owner_th"] = response.data.data_last_view[i].name_th;
              file_lastview["file_permission"] = response.data.data_last_view[i].permission_account;
              file_lastview["permission_department_setting"] = response.data.data_last_view[i].permission_setting;
              file_lastview["permission_role_setting"] = response.data.data_last_view[i].permission_role_setting;
              file_lastview["priority"] = response.data.data_last_view[i].priority;
              file_lastview["file_size"] = response.data.data_last_view[i].size;
              file_lastview["status_lock_data"] = response.data.data_last_view[i].status_lock_data;
              file_lastview["status_lock_file"] = response.data.data_last_view[i].status_lock_file;
              file_lastview["file_status"] = response.data.data_last_view[i].status;
              file_lastview["file_status_share"] = response.data.data_last_view[i].status_share;
              file_lastview["file_status_sharelink"] = response.data.data_last_view[i].status_share_link;
              file_lastview["file_department"] = "";
              file_lastview["system"] = response.data.data_last_view[i].system;
              file_lastview["type"] = response.data.data_last_view[i].types;
              file_lastview["status_expired"] = response.data.data_last_view[i].status_expired;
              file_lastview["expiration_time"] = response.data.data_last_view[i].expiration_time;
              file_lastview["file_icon"] = gb_setIconFiles.iconExtensionFilesFolder_new(type, typefile[typefile.length - 1]);
              file_lastview["file_type"] = type == "folder" ? "folder" : typefile[typefile.length - 1];
              file_lastview["user_id"] = response.data.data_last_view[i].user_id;
              file_lastview["file_thumbnail"] = "";
              file_lastview["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
              file_lastview["editing_status"] = response.data.data_last_view[i].editing_status;
              file_lastview["update_by"] = response.data.data_last_view[i].update_by;
              file_lastview["last_update_name"] = response.data.data_last_view[i].last_update_name;
              file_lastview["warning_expiration_time"] = response.data.data_last_view[i].warning_expiration_time;

              // file_lastview["hashtag"] = response.data.data_last_view[i].hashtag;

              this.filesLastView.push(file_lastview);
              this.fn_loadthumbnail(response.data.data_last_view[i].id, typefile[typefile.length - 1]);
            }
          }
        });
    },
    // ดึงข้อมูลโฟลเดอร์และไฟล์ แบบ Scroll โดยจะดึงแบบ limit offset
    async fn_loadfileandfolder_scrolling() {
      //console.log("jjj",this.$route);
      this.disablescrolling = true;
      this.loaddataprogress = true;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);
      console.log("cal_offset", cal_offset);
      console.log("cal_limit", cal_limit);
      if (cal_offset == 0) {
        this.rootfile = [];
        this.buff = [];
      }

      if (this.dataAccountActive["type"] === "Business") {
        if (cal_offset == 0) {
          let datafolder = {};
          datafolder["file_id"] = this.dataAccountActive["business_info"]["my_folder_id"];
          datafolder["file_name"] = this.$store.getters.dataTypeusercurrent(
            this.$store.state.account_active["type"],
            this.$t("default")
          );
          datafolder["file_lastdtm"] = "-";
          datafolder["file_owner_eng"] = "-";
          datafolder["file_owner_th"] = "-";
          datafolder["file_size"] = "-";
          datafolder["file_department"] = "";
          datafolder["file_status"] = "M";
          datafolder["file_type"] = "folder";
          datafolder["file_icon"] = ["mdi-folder-network", "blue darken-4"];
          this.rootfile.push(datafolder);
          this.buff.push(datafolder);
        }
      }

      // Personal
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.account_active["directory_id"],
        status_file: "",
        status_folder: "",
        limit: cal_limit,
        offset: cal_offset,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/select_files_and_folder", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          this.loaddataprogress = false;
          if (response.data.status === "OK") {
            this.fn_loadfilelastview();

            //console.log("File last view", this.filesLastView);
            // File and Folder
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;
              let yourfolder_path = response.data.your_folder[0].full_path_id;
              // console.log("full_path_id your folder",response.data.your_folder[0].full_path_id);
              dataFileAndFolder["account_reciever"] = "";
              dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_id"] = response.data.data[i].account_id;
              dataFileAndFolder["countnum"] = response.data.data[i].countnum;
              dataFileAndFolder["file_createdtm"] = response.data.data[i].cre_dtm;
              dataFileAndFolder["folder_id"] = response.data.data[i].folder_id;
              dataFileAndFolder["file_id"] = response.data.data[i].id;
              dataFileAndFolder["file_lastdtm"] = response.data.data[i].last_dtm;
              dataFileAndFolder["file_linkshare"] = response.data.data[i].link;
              dataFileAndFolder["file_name"] = response.data.data[i].name;
              dataFileAndFolder["file_owner_eng"] = response.data.data[i].name_eng;
              dataFileAndFolder["file_owner_th"] = response.data.data[i].name_th;
              dataFileAndFolder["file_permission"] =
                this.dataAccountActive["type"] === "Business"
                  ? yourfolder_path === this.dataAccountActive.directory_id
                    ? { admin_data: "False", delete: "True", download: "True", edit: "True", upload: "True", view_only: "True" }
                    : response.data.data[i].permission_account
                  : response.data.data[i].permission_account;
              dataFileAndFolder["permission_department_setting"] = response.data.data[i].permission_department_setting;
              dataFileAndFolder["permission_role_setting"] = response.data.data[i].permission_role_setting;
              dataFileAndFolder["permission_business_setting"] = response.data.data[i].permission_business_setting;
              dataFileAndFolder["permission_setting"] = response.data.data[i].permission_setting;
              dataFileAndFolder["priority"] = response.data.data[i].priority;
              dataFileAndFolder["file_size"] = response.data.data[i].size;
              dataFileAndFolder["file_status"] = response.data.data[i].status;
              dataFileAndFolder["file_status_share"] = response.data.data[i].status_share;
              dataFileAndFolder["file_status_sharelink"] = response.data.data[i].status_share_link;
              dataFileAndFolder["system"] = response.data.data[i].system;
              dataFileAndFolder["type"] = response.data.data[i].data_type;
              dataFileAndFolder["file_icon"] = gb_setIconFiles.iconExtensionFilesFolder_new(type, typefile[typefile.length - 1]);
              dataFileAndFolder["file_type"] = type == "folder" ? "folder" : typefile[typefile.length - 1];
              dataFileAndFolder["file_department"] = "";
              dataFileAndFolder["user_id"] = response.data.data[i].user_id;
              dataFileAndFolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
              dataFileAndFolder["status_lock_data"] = response.data.data[i]["status_lock_data"];
              dataFileAndFolder["version_tag"] = response.data.data[i]["version_tag"];
              dataFileAndFolder["tag_id"] = response.data.data[i]["tag_id"];
              dataFileAndFolder["status_expired"] = response.data.data[i]["status_expired"];
              dataFileAndFolder["expiration_time"] = response.data.data[i]["expiration_time"];
              dataFileAndFolder["hashtag"] = response.data.data[i]["hashtag"];
              dataFileAndFolder["file_thumbnail"] = "";
              dataFileAndFolder["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
              dataFileAndFolder["editing_status"] = response.data.data[i]["editing_status"];
              dataFileAndFolder["update_by"] = response.data.data[i]["update_by"];
              dataFileAndFolder["last_update_name"] = response.data.data[i]["last_update_name"];
              dataFileAndFolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];
              

              this.rootfile.push(dataFileAndFolder);
              this.buff.push(dataFileAndFolder);
              this.fn_loadthumbnail(response.data.data[i].id, typefile[typefile.length - 1]);
            }
            // console.log("root",this.rootfile);
            this.totaldata = {
              totalall: response.data.count,
              totalfile: response.data.count_file,
              totalfolder: response.data.count_folder,
            };
            this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            this.aspectData = [
              { aspect: response.data.your_folder[0]["aspect"], aspect_key: response.data.your_folder[0]["aspect_key"] },
            ];
            this.disablescrolling = this.totaldata["totalall"] <= this.rootfile.length;
            this.departmentid = response.data.your_folder[0]["department_id"];
            console.log("this.departmentid", this.departmentid);
            // console.log("Newrootfile", this.newrootfile);
          } else {
            this.disablescrolling = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          this.disablescrolling = true;
          Toast.fire({ icon: "error", title: this.$t("toast.cannotconnectonebox") });
        });
    },

    // ดึงข้อมูลโฟลเดอร์และไฟล์ ในกรณีที่ต้องการดึงใหม่ตั้งแต่ต้น ใช้สำหรับให้ child component เรียกข้อมูล
    async fn_loadfileandfolder_scrolling_child() {
      this.heardertextsearch = false;
      console.log("fn_loadfileandfolder_scrolling_child  first load");
      this.disablescrolling = true;
      this.loaddataprogress = true;
      this.page = 1;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);
      // console.log(cal_offset)
      if (cal_offset == 0) {
        this.rootfile = [];
        this.buff = [];
      }

      if (this.dataAccountActive["type"] === "Business") {
        if (cal_offset == 0) {
          let datafolder = {};
          datafolder["file_id"] = this.dataAccountActive["business_info"]["my_folder_id"];
          datafolder["file_name"] = this.$store.getters.dataTypeusercurrent(
            this.$store.state.account_active["type"],
            this.$t("default")
          );
          datafolder["file_lastdtm"] = "-";
          datafolder["file_owner_eng"] = "-";
          datafolder["file_owner_th"] = "-";
          datafolder["file_size"] = "-";
          datafolder["file_department"] = "";
          datafolder["file_status"] = "M";
          datafolder["file_type"] = "folder";
          datafolder["file_icon"] = ["mdi-folder-network", "blue darken-4"];
          this.rootfile.push(datafolder);
          this.buff.push(datafolder);
        }
      }

      // Personal
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.account_active["directory_id"],
        status_file: "",
        status_folder: "",
        limit: cal_limit,
        offset: cal_offset,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/select_files_and_folder", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          this.loaddataprogress = false;
          console.log("response myfile", response);
          if (response.data.status === "OK") {
            this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            this.aspectData = [
              {
                aspect: response.data.your_folder[0]["aspect"],
                aspect_key: response.data.your_folder[0]["aspect_key"],
              },
            ];
            this.fn_loadfilelastview();
            // this.filesLastView = [];
            // for (let i = 0; i < response.data.data_last_view.length; i++) {
            //   let file_lastview = {};
            //   let typefile = response.data.data_last_view[i].name.split(".");
            //   let type = response.data.data_last_view[i].data_type;

            //   file_lastview["account_reciever"] = "";
            //   file_lastview["account_sender"] = this.dataAccountId;
            //   file_lastview["account_id"] = response.data.data_last_view[i].account_id;
            //   file_lastview["countnum"] = response.data.data_last_view[i].countnum;
            //   file_lastview["file_createdtm"] = response.data.data_last_view[i].cre_dtm;
            //   file_lastview["folder_id"] = response.data.data_last_view[i].folder_id;
            //   file_lastview["file_id"] = response.data.data_last_view[i].id;
            //   file_lastview["file_lastdtm"] = response.data.data_last_view[i].last_dtm;
            //   file_lastview["file_linkshare"] = response.data.data_last_view[i].link;
            //   file_lastview["file_name"] = response.data.data_last_view[i].name;
            //   file_lastview["file_owner_eng"] = response.data.data_last_view[i].name_eng;
            //   file_lastview["file_owner_th"] = response.data.data_last_view[i].name_th;
            //   file_lastview["file_permission"] = response.data.data_last_view[i].permission_account;
            //   file_lastview["permission_department_setting"] = response.data.data_last_view[i].permission_setting;
            //   file_lastview["priority"] = response.data.data_last_view[i].priority;
            //   file_lastview["file_size"] = response.data.data_last_view[i].size;
            //   file_lastview["file_status"] = response.data.data_last_view[i].status;
            //   file_lastview["status_lock_data"] = response.data.data_last_view[i].status_lock_data;
            //   file_lastview["file_status_share"] = response.data.data_last_view[i].status_share;
            //   file_lastview["file_status_sharelink"] = response.data.data_last_view[i].status_share_link;
            //   file_lastview["system"] = response.data.data_last_view[i].system;
            //   file_lastview["type"] = response.data.data_last_view[i].types;
            //   file_lastview["file_department"] = "";
            //   file_lastview["status_expired"] = response.data.data_last_view[i].status_expired;
            //   file_lastview["expiration_time"] = response.data.data_last_view[i].expiration_time;
            //   file_lastview["file_icon"] = gb_setIconFiles.iconExtensionFilesFolder_new(type, typefile[typefile.length - 1]);
            //   file_lastview["file_type"] = type == "folder" ? "folder" : typefile[typefile.length - 1];
            //   file_lastview["user_id"] = response.data.data_last_view[i].user_id;
            //   file_lastview["file_thumbnail"] = "";
            //   file_lastview["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
            //   file_lastview["editing_status"] = response.data.data_last_view[i].editing_status;
            //   file_lastview["update_by"] = response.data.data_last_view[i].update_by;
            //   file_lastview["last_update_name"] = response.data.data_last_view[i].last_update_name;

            //   // file_lastview["hashtag"] = response.data.data_last_view[i].hashtag;
            //   this.filesLastView.push(file_lastview);
            //   this.fn_loadthumbnail(response.data.data_last_view[i].id, typefile[typefile.length - 1]);
            // }
            // console.log("first load last view", this.filesLastView);
            // File and Folder
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;
              let yourfolder_path = response.data.your_folder[0].full_path_id;

              dataFileAndFolder["account_reciever"] = "";
              dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_id"] = response.data.data[i].account_id;
              dataFileAndFolder["countnum"] = response.data.data[i].countnum;
              dataFileAndFolder["file_createdtm"] = response.data.data[i].cre_dtm;
              dataFileAndFolder["folder_id"] = response.data.data[i].folder_id;
              dataFileAndFolder["file_id"] = response.data.data[i].id;
              dataFileAndFolder["file_lastdtm"] = response.data.data[i].last_dtm;
              dataFileAndFolder["file_linkshare"] = response.data.data[i].link;
              dataFileAndFolder["file_name"] = response.data.data[i].name;
              dataFileAndFolder["file_owner_eng"] = response.data.data[i].name_eng;
              dataFileAndFolder["file_owner_th"] = response.data.data[i].name_th;
              dataFileAndFolder["file_permission"] =
                this.dataAccountActive["type"] === "Business"
                  ? yourfolder_path === this.dataAccountActive.directory_id
                    ? { admin_data: "False", delete: "True", download: "True", edit: "True", upload: "True", view_only: "True" }
                    : response.data.data[i].permission_account
                  : response.data.data[i].permission_account;
              dataFileAndFolder["permission_department_setting"] = response.data.data[i].permission_department_setting;
              dataFileAndFolder["permission_role_setting"] = response.data.data[i].permission_role_setting;
              dataFileAndFolder["permission_business_setting"] = response.data.data[i].permission_business_setting;
              dataFileAndFolder["permission_setting"] = response.data.data[i].permission_setting;
              dataFileAndFolder["priority"] = response.data.data[i].priority;
              dataFileAndFolder["file_size"] = response.data.data[i].size;
              dataFileAndFolder["file_status"] = response.data.data[i].status;
              dataFileAndFolder["file_status_share"] = response.data.data[i].status_share;
              dataFileAndFolder["file_department"] = "";
              dataFileAndFolder["file_status_sharelink"] = response.data.data[i].status_share_link;
              dataFileAndFolder["system"] = response.data.data[i].system;
              dataFileAndFolder["type"] = response.data.data[i].types;
              dataFileAndFolder["file_icon"] = gb_setIconFiles.iconExtensionFilesFolder_new(type, typefile[typefile.length - 1]);
              dataFileAndFolder["file_type"] = type == "folder" ? "folder" : typefile[typefile.length - 1];
              dataFileAndFolder["user_id"] = response.data.data[i].user_id;
              dataFileAndFolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
              dataFileAndFolder["status_lock_data"] = response.data.data[i]["status_lock_data"];
              dataFileAndFolder["status_expired"] = response.data.data[i]["status_expired"];
              dataFileAndFolder["expiration_time"] = response.data.data[i]["expiration_time"];
              dataFileAndFolder["version_tag"] = response.data.data[i]["version_tag"];
              dataFileAndFolder["tag_id"] = response.data.data[i]["tag_id"];
              dataFileAndFolder["hashtag"] = response.data.data[i]["hashtag"];
              dataFileAndFolder["file_thumbnail"] = "";
              dataFileAndFolder["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
              dataFileAndFolder["editing_status"] = response.data.data[i]["editing_status"];
              dataFileAndFolder["update_by"] = response.data.data[i]["update_by"];
              dataFileAndFolder["last_update_name"] = response.data.data[i]["last_update_name"];
              dataFileAndFolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];
              

              this.rootfile.push(dataFileAndFolder);
              this.buff.push(dataFileAndFolder);
              this.fn_loadthumbnail(response.data.data[i].id, typefile[typefile.length - 1]);
            }
            console.log("this.rootfile", this.rootfile);
            this.totaldata = {
              totalall: response.data.count,
              totalfile: response.data.count_file,
              totalfolder: response.data.count_folder,
            };
            this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            this.aspectData = [
              { aspect: response.data.your_folder[0]["aspect"], aspect_key: response.data.your_folder[0]["aspect_key"] },
            ];
            this.disablescrolling = this.totaldata["totalall"] <= this.rootfile.length;
            this.departmentid = response.data.your_folder[0]["department_id"];
            console.log("this.departmentid", this.departmentid);
            // console.log("Newrootfile", this.newrootfile);
          } else {
            this.disablescrolling = true;
          }
        })
        .catch((error) => {
          console.log("API LOADFILE&FOLDER : ", str(error));
          this.loaddataprogress = false;
          this.disablescrolling = true;
          Toast.fire({ icon: "error", title: this.$t("toast.cannotconnectonebox") });
        });
    },
    keyup(event) {
      if (event.getModifierState("CapsLock")) {
        console.log("CapsLock");
      } else {
        console.log("UnCapsLock");
      }
    },
    addEventcopypaste() {
      window.addEventListener("keypress", this.fn_checkkeyevent, true);
    },
    removeEventcopypaste() {
      window.removeEventListener("keypress", this.fn_checkkeyevent, true);
    },
  },

  mounted() {
    this.removeEvent();
    this.addEvent();

    // this.removeEventcopypaste();
    // this.addEventcopypaste();

    this.loaddataprogress = false;
    this.$store.dispatch("department_access_id", "");
    this.loadstorage();
    this.disablescrolling = false;
    // this.loadfolder();
    // this.loadfileandfolder();
    // localStorage.setItem("changeStoreUI", "true");
    // this.loadcolor();
  },
  //ดักเมื่อมีการกด ctrl+c และ ctrl+v  ************
  created() {
    // this.removeEventcopypaste();
    // this.addEventcopypaste();
    // window.addEventListener('keypress', this.fn_checkkeyevent);
    // this.listcopy = this.filemultipledownload;
    //this.addEvent();
  },
  beforeDestroy() {
    //this.removeEvent();
    this.removeevent = true;
    this.removeEventcopypaste();
  },
};
</script>

<style>
.drag-selector {
  padding: 15px;
}
.drag-selector__item {
  align-items: center;
  /* height: 40px;
  padding-left: 16px;
  vertical-align: middle;
  display: flex;
  margin: 5px; */
  /* background-color: rebeccapurple; */
}

/* .drag-selector__item.selected { */
/* outline-offset: 4px;
  outline-width: 2px;
  outline-style: double;
  outline-color: rgb(28, 117, 235); */
/* } */
/* #cardItem {
  background-color: rebeccapurple;
} */
template td {
  padding: 0px;
  margin: 0px;
}
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
#demo {
  width: 100%;
  height: 100%;
}

div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}

div#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 175ms, opacity 175ms;
  display: table;
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
  font: bold 42px Sarabun, system-ui, Tahoma, sans-serif;
}

#dropzone {
  min-height: 100%;
}

/* #thiscontainer::-webkit-scrollbar {
width: 30em;
display: inline !important; 
} */

#thiscontainer_myfile {
  height: calc(101vh - 180px);
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  /* max-width: 100%; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.pointer {
  cursor: pointer;
}

.v-progress-circular {
  margin: 1rem;
}
#slideritem:hover {
  background-color: #d6eaf8;
  border-radius: 5px;
}
</style>
